import * as React from "react";
import { SVGProps } from "react";
const SpecialFormsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        d="M8.274 6.868v-.5H5.55a.058.058 0 0 1-.018-.015.168.168 0 0 1-.036-.11v-2.5c0-.05.018-.09.036-.11a.058.058 0 0 1 .02-.015h2.722v-3c0-.05.018-.09.036-.11a.058.058 0 0 1 .02-.015H10.553c.003.002.01.006.018.015.018.02.036.06.036.11v3h2.722l.002.001c.003.001.01.005.017.014.018.02.037.06.037.11v2.5c0 .051-.019.09-.037.11l.374.332-.374-.332a.057.057 0 0 1-.02.015h-2.72v3c0 .051-.02.09-.037.11l.373.332-.373-.332a.057.057 0 0 1-.02.015H8.328a.058.058 0 0 1-.018-.015.168.168 0 0 1-.036-.11v-2.5Zm11.032 6.535c.15.23.217.525.177.82-.04.295-.18.546-.372.705l-4.687 3.888a2.878 2.878 0 0 1-1.825.678H.554l-.003-.001a.061.061 0 0 1-.019-.015.172.172 0 0 1-.037-.111v-3.746c0-.051.019-.09.037-.11a.061.061 0 0 1 .022-.017h2.112l.144-.13 1.614-1.475c.64-.584 1.431-.895 2.236-.895h5.556c.08 0 .16.02.237.06.078.04.151.102.214.184.062.083.11.184.137.296l.486-.119-.486.119c.027.112.033.23.016.346v.001c-.056.377-.364.613-.665.613H9.44c-.3 0-.575.135-.767.35l.374.333-.374-.332c-.19.214-.289.493-.289.774 0 .28.099.56.29.774l.373-.332-.374.332c.192.216.466.35.767.35H13.808l.139-.114 4.155-3.445a.823.823 0 0 1 .306-.163.736.736 0 0 1 .323-.017c.107.018.214.06.312.128a.962.962 0 0 1 .263.271Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SpecialFormsIcon;
