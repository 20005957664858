import { useEffect, useState, useRef } from "react";
import { Button, Divider, Drawer, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import HeaderComponent from "../header";
import SidebarComponent from "../sidebar";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getUiIncomeLimit, getVerifyAuth } from "../../store/thunk/authThunk";
import { setFaqDialog, setPreviewDialog } from "../../store/slices/flowFormSlice";
import FAQs from "../../pages/faq";
import PreviewPDF from "../../pages/previewPdf";

import { getAllPromptsData, getSingleForm, replenish } from "../../store/thunk/formThunk";
import {
  anonymousUser,
  logErrorFunction,
  logFunction,
  shouldThirdPartyIntegration,
} from "../../helper/service";
import SideLogo from "../../assets/images/logo-white.png";
import lawFirmLogos from "../../config/lawFirmLogos";
import { getReady } from "../../store/thunk/userThunk";
import { Error } from "@mui/icons-material";

const drawerWidth = 400;
const LayoutComponent = ({ children }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const valueReadyRef: any = useRef();
  const hidden = useMediaQuery("(min-width:900px)");
  const { authConfig } = useAppSelector((state: any) => state.authReducer);
  const { progressData } = useAppSelector((state: any) => state.documentReducer);
  const { isCaseTypeSubmitted } = useAppSelector((state: any) => state.notificationReducer);
  let uiFlowJsonId = localStorage.getItem("uiFlowJsonId")
    ? localStorage.getItem("uiFlowJsonId")
    : "";
  const billingEntityId = localStorage.getItem("billingEntityId");
  const logoSrc = billingEntityId ? lawFirmLogos[billingEntityId] : null;

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [sectionList, setSectionList] = useState<any[]>([]);
  const [locked, setLocked] = useState<any>(false);
  const [isInIframe, setIsInIframe] = useState(false);

  const isFaqDialog = useAppSelector((state: any) => state.formReducer.faqDialog);
  const previewDialog = useAppSelector((state: any) => state.formReducer.previewDialog);
  const { getSignleFormData } = useAppSelector((state: any) => state?.formReducer);
  const [openFAQ, setIsOpenFAQ] = useState<any>(false);
  const [openPreview, setIsOpenPreview] = useState<any>(false);
  const [route, setRoute] = useState<any>(false);
  const [sidebarLogoURL, setSidebarLogoURL] = useState("");
  const { sidebarLogo } = useAppSelector((state: any) => state.billingReducer);
  const [readyInterval, setReadyInterval] = useState<any>(null);

  const isAdmin = localStorage.getItem("userType")?.toLocaleLowerCase() == "admin" ? true : false;

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);
  const { caseTypes } = bootstrapData || {};

  let localIsBootstrapCalled: any = localStorage.getItem("isBootstrapCalled");
  const isBootstrapCalled: any = JSON.parse(localIsBootstrapCalled);

  const lockedApp = localStorage.getItem("lockedApp");

  const isCaseType = localStorage.getItem("isCaseType") ? localStorage.getItem("isCaseType") : null;
  const role: any = localStorage.getItem("role");
  const isIntelligenceEnabled = bootstrapData?.features?.find(
    (f: any) => f?.name === "IntelligenceEnabled"
  )?.value;
  useEffect(() => {
    if (isIntelligenceEnabled && role === "lawyer") {
      dispatch(getAllPromptsData());
    }
  }, [isIntelligenceEnabled, role]);

  useEffect(() => {
    if (window.top !== window.self) {
      setIsInIframe(true);
    }
  }, [location]);

  useEffect(() => {
    if (sidebarLogo !== null) {
      setSidebarLogoURL(sidebarLogo);
    }
  }, [sidebarLogo]);

  useEffect(() => {
    dispatch(setFaqDialog(false));
    dispatch(setPreviewDialog(false));
  }, []);

  // useEffect(() => {
  //   if (!isAuthenticated && !anonymousUser()) {
  //     var w = window as any;
  //     var ic = w.Intercom;
  //     ic("shutdown");
  //     localStorage.clear();
  //     logout({ logoutParams: { returnTo: window.location.origin } });
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    if (bootstrapData?.refreshReady && isBootstrapCalled) {
      dispatch(getUiIncomeLimit());
    }
  }, [bootstrapData?.refreshReady]);

  useEffect(() => {
    valueReadyRef.current = readyInterval;
  }, [readyInterval]);

  const onClearReadyInterval = () => {
    setReadyInterval(null);
    clearInterval(valueReadyRef.current);
  };

  // Continue call to check ready status
  useEffect(() => {
    if (isBootstrapCalled) {
      if (bootstrapData?.refreshReady === false && bootstrapData?.refreshReady !== null) {
        const readyInterval = setInterval(
          () =>
            dispatch(getReady()).then((res: any) => {
              if (res?.payload?.data === true) {
                bootstrapData.refreshReady = true;
                localStorage.setItem("bootstrapData", JSON.stringify(bootstrapData));
                onClearReadyInterval();
              }
            }),
          2000
        );
        setReadyInterval(readyInterval);
      } else {
        onClearReadyInterval();
      }
    }
  }, []);

  // Stop Status API after 30 seconds if status=paid/unpaid and stop api call of ready
  useEffect(() => {
    setTimeout(() => {
      onClearReadyInterval();
    }, 30000);
  }, []);

  useEffect(() => {
    if (uiFlowJsonId && isBootstrapCalled && isCaseType === "true") {
      dispatch(getSingleForm({ uiFlowJsonId }));
    }
  }, [localStorage.getItem("uiFlowJsonId"), isCaseTypeSubmitted, isCaseType]);

  // useEffect(() => {
  //   if (bootstrapData && bootstrapData?.refreshReady === true) {
  //     dispatch(getUiIncomeLimit());
  //   }
  // }, [bootstrapData?.refreshReady]);

  useEffect(() => {
    const unSavedFormValues = sessionStorage.getItem("unSavedFormValues");

    if (unSavedFormValues) {
      try {
        const parsedValues = JSON.parse(unSavedFormValues);

        if (parsedValues?.ui_form_lock_state !== undefined) {
          setLocked(parsedValues.ui_form_lock_state);
        } else {
          setLocked(false);
        }
      } catch (error) {
        logErrorFunction("Error parsing session storage data:" + error);
        setLocked(false);
      }
    } else {
      setLocked(false);
    }
  }, []);

  useEffect(() => {
    const isIframeUser = (function () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true; // In case of cross-origin restrictions
      }
    })();

    if (location.pathname && sectionList.length) {
      const dynamicPathName = decodeURI(location.pathname);
      const splittedArray = dynamicPathName?.split("/");
      const mainId = splittedArray[2];

      if (mainId) {
        const selectedSection = sectionList?.find((section) => section?.data?.nodeTitle === mainId);

        if (!selectedSection?.id) {
          navigate("/");
        }
      } else {
        if (isIframeUser) {
          const isEligibilityFilled = progressData?.find(
            (section: any) => section?.sectionKey === "General_Eligibility"
          );
          if (isEligibilityFilled && isEligibilityFilled?.percentComplete === 0) {
            navigate("/home");
          }
          logFunction("Iframe user detected");
        } else {
          if (isCaseType && isCaseType !== "false") {
            const isEligibilityFilled = progressData?.find(
              (section: any) => section?.sectionKey === "General_Eligibility"
            );
            if (isEligibilityFilled && isEligibilityFilled?.percentComplete === 0) {
              navigate("/eligibility-check/Eligibility%20Quiz");
            }
          }
        }
      }
    }
  }, [location.pathname, sectionList, progressData]);

  const convertIntoUiJson = (jsonData: any) => {
    try {
      let orderCounts: any = [];

      return (
        !!jsonData &&
        jsonData.length > 0 &&
        jsonData.map((val: any) => {
          let childList = jsonData
            .filter((row: any) => val.id === row.parentNode)
            .map((row: any) => row.id);
          let order = orderCounts.filter((row: any) => val.parentNode === row);
          orderCounts.push(val.parentNode);

          return {
            id: val.id,
            // nodeTitle: val?.data?.nodeTitle ? val?.data?.nodeTitle : "",
            parentNode: val?.parentNode,
            order: (!!order?.length ? order?.length : 0) + 1,
            children: childList,
            // data: val?.data,
          };
        })
      );
    } catch (error) {
      alert("json is not correct.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let getValue =
        getSignleFormData && getSignleFormData?.flowJson
          ? JSON.parse(getSignleFormData?.flowJson)
          : "";
      if (!!getValue?.nodes && getValue?.nodes?.length > 0) {
        let getJsonData: any = await convertIntoUiJson(getValue?.nodes);

        const mergedObj = getValue?.nodes?.flatMap((flowJsonData: any) =>
          getJsonData
            ?.filter((getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id)
            .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
        );

        let sectionData = mergedObj.filter((nodesData: any) => {
          if (
            nodesData?.data?.nodeType &&
            (nodesData?.data?.nodeType?.toLowerCase() == "section" ||
              nodesData?.data?.nodeType?.toLowerCase() == "text") &&
            !!nodesData?.data?.nodeTitle
          ) {
            return nodesData;
          }
        });

        if (!!sectionData?.length && sectionData?.length > 0) {
          setSectionList(sectionData);
        }
      }
    };

    fetchData();
  }, [getSignleFormData]);

  useEffect(() => {
    if (bootstrapData?.refreshReady === true && isBootstrapCalled) {
      dispatch(replenish());
      const accessToken = localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : null;

      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData") || "{}")
        : null;

      if (authConfig) {
        if (authConfig?.appInsightsConnectionString) {
          const browserHistory = createBrowserHistory({ basename: "" } as any);
          var reactPlugin = new ReactPlugin();
          const clickPluginInstance = new ClickAnalyticsPlugin();
          const clickPluginConfig = {
            autoCapture: true,
          };

          // Application Insights Configuration
          const configObj = {
            connectionString: authConfig?.appInsightsConnectionString,
            extensions: [clickPluginInstance, reactPlugin],
            extensionConfig: {
              [reactPlugin.identifier]: { history: browserHistory },
              [clickPluginInstance.identifier]: clickPluginConfig,
            },
            enableAutoRouteTracking: true,
          };

          const appInsights = new ApplicationInsights({ config: configObj });
          // appInsights.setAuthenticatedUserContext(
          //   authConfig?.id,
          //   userData?.email
          // );

          // appInsights.loadAppInsights();
          // appInsights.trackPageView();
        }
      }
    }
  }, [authConfig, bootstrapData?.refreshReady]);

  useEffect(() => {
    if (bootstrapData && (bootstrapData?.isDisabled || bootstrapData?.isDeleted)) {
      navigate("/disable-user");
    }
  }, [bootstrapData]);

  useEffect(() => {
    setRoute(location?.pathname || "");
  }, [location]);

  useEffect(() => {
    setIsOpenFAQ(isFaqDialog);
  }, [isFaqDialog]);

  useEffect(() => {
    setIsOpenPreview(previewDialog);
  }, [previewDialog]);

  const toggleFaqDrawer = () => {
    dispatch(setFaqDialog(!openFAQ));
    dispatch(setPreviewDialog(false));
  };
  const togglePreviewDrawer = () => {
    dispatch(setFaqDialog(false));
    dispatch(setPreviewDialog(!openPreview));
  };

  return (
    <div>
      <div className="flex md:hidden overflow-hidden justify-between items-center p-2.5 bg-[#111827] h-[77px]">
        {isInIframe ? (
          sidebarLogoURL ? (
            <img src={sidebarLogoURL} alt="Law Firm Logo" className="sideLogo" />
          ) : (
            <></>
          )
        ) : (
          <img src={SideLogo} alt="Logo" className="sideLogo" />
        )}

        <button
          data-drawer-target="sidebar-multi-level-sidebar"
          data-drawer-toggle="sidebar-multi-level-sidebar"
          aria-controls="sidebar-multi-level-sidebar"
          type="button"
          className="inline-flex items-center p-2 text-sm text-white hover:text-black rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 hemburger-menu"
          onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
      </div>
      <div>
        <div
          className={`main ml-auto fixed w-full overflow-hidden ${
            isSidebarVisible ? "sideBarHide" : "sideBarShow"
          }`}
        >
          <SidebarComponent setIsSidebarVisible={setIsSidebarVisible} />
          <div
            className={`main mainContentSection h-screen ${
              isSidebarVisible ? "sideBarHide" : "sideBarShow"
            } ${sessionStorage.getItem("Usapath-Emulate-UserId") ? "has-banner" : ""} ${
              caseTypes?.length > 1 ? "has-multi-caseTypes" : ""
            } ${lockedApp === "true" ? "locked-forms" : ""}`}
          >
            {isSidebarVisible && (
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => setIsSidebarVisible(!isSidebarVisible)}
              />
            )}
            <div className={(openFAQ || openPreview) && hidden ? "openFAQWdith" : "closeFAQWdith"}>
              {route !== "/upload-document" && <HeaderComponent />}
              <div className="h-full mainInnerContent">{children}</div>
            </div>

            {openFAQ && (
              <Drawer
                sx={{
                  maxWidth: drawerWidth,
                  width: "100%",
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    maxWidth: drawerWidth,
                    width: "100%",
                    ...(hidden && {
                      top:
                        sessionStorage.getItem("Usapath-Emulate-UserId") && lockedApp === "true"
                          ? "243px"
                          : sessionStorage.getItem("Usapath-Emulate-UserId") || lockedApp === "true"
                          ? "186px"
                          : "126px",
                    }),
                    ...(hidden && {
                      height:
                        sessionStorage.getItem("Usapath-Emulate-UserId") && lockedApp === "true"
                          ? "calc(100vh - 243px)"
                          : sessionStorage.getItem("Usapath-Emulate-UserId") || lockedApp === "true"
                          ? "calc(100vh - 187px)"
                          : anonymousUser()
                          ? "calc(100vh - 40px)"
                          : "calc(100vh - 126px)",
                    }),
                  },
                }}
                className={
                  route !== "/upload-document"
                    ? `faqDrawer ${
                        !isAdmin ? (anonymousUser() ? "AnonymousUserBox" : " UserBox") : ""
                      }`
                    : ""
                }
                variant={hidden ? "persistent" : "temporary"}
                anchor="right"
                open={openFAQ}
              >
                <FAQs toggleFaqDrawer={toggleFaqDrawer} />
              </Drawer>
            )}
            {openPreview && (
              <Drawer
                sx={{
                  maxWidth: drawerWidth,
                  width: "100%",
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    maxWidth: drawerWidth,
                    ...(hidden && {
                      top:
                        sessionStorage.getItem("Usapath-Emulate-UserId") && lockedApp === "true"
                          ? "243px"
                          : sessionStorage.getItem("Usapath-Emulate-UserId") || lockedApp === "true"
                          ? "186px"
                          : "126px",
                    }),
                    ...(hidden && {
                      height:
                        sessionStorage.getItem("Usapath-Emulate-UserId") && lockedApp === "true"
                          ? "calc(100vh - 243px)"
                          : sessionStorage.getItem("Usapath-Emulate-UserId") || lockedApp === "true"
                          ? "calc(100vh - 187px)"
                          : anonymousUser()
                          ? "calc(100vh - 40px)"
                          : "calc(100vh - 100px)",
                    }),
                    width: "100%",
                  },
                }}
                className={`${!isAdmin ? (anonymousUser() ? "AnonymousUserBox" : " UserBox") : ""}`}
                variant={hidden ? "persistent" : "temporary"}
                anchor="right"
                open={openPreview}
              >
                <PreviewPDF togglePreviewDrawer={togglePreviewDrawer} hidden={hidden} />
              </Drawer>
            )}

            {/* <FooterComponent /> */}
          </div>
        </div>
      </div>
      <div id="errorScreen" className="errorScreen">
        <div>
          <Typography variant="h6">
            <Error sx={{ color: "#d32f2f" }} /> Something went wrong
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="inherit" sx={{ fontSize: 16 }}>
            Please refresh the page and try again.
          </Typography>
          <small style={{ color: "#707070" }}>
            <b>Request ID: </b>
            <span></span>
          </small>
          <Button
            variant="contained"
            sx={{ mt: 2, width: "100%" }}
            size="small"
            onClick={() => window.location.reload()}
          >
            Refresh the page
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
