import * as React from "react";
import { SVGProps } from "react";
const FamilymarriageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M8 10.667a2.667 2.667 0 1 1 0-5.334 2.667 2.667 0 0 1 0 5.334Zm0-4a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666Zm4 8.666a4 4 0 0 0-8 0 .667.667 0 1 0 1.333 0 2.667 2.667 0 0 1 5.334 0 .667.667 0 1 0 1.333 0Zm0-10A2.667 2.667 0 1 1 12 0a2.667 2.667 0 0 1 0 5.333Zm0-4A1.334 1.334 0 1 0 12 4a1.334 1.334 0 0 0 0-2.667ZM16 10a4.004 4.004 0 0 0-4-4 .667.667 0 1 0 0 1.333A2.667 2.667 0 0 1 14.667 10 .667.667 0 1 0 16 10ZM4 5.333A2.667 2.667 0 1 1 4 0a2.667 2.667 0 0 1 0 5.333Zm0-4A1.333 1.333 0 1 0 4 4a1.333 1.333 0 0 0 0-2.667ZM1.333 10A2.667 2.667 0 0 1 4 7.333.667.667 0 1 0 4 6a4.004 4.004 0 0 0-4 4 .667.667 0 1 0 1.333 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FamilymarriageIcon;
