import * as React from "react";
import { SVGProps } from "react";
const SadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FFD664"
        d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Z"
      />
      <path
        fill="#000"
        fillOpacity={0.024}
        d="M100 50c0-5.625-.938-11.055-2.656-16.094-3.047 23.75-17.11 67.539-80.117 53.867C26.016 95.391 37.46 100 50 100c27.617 0 50-22.383 50-50Z"
      />
      <path
        fill="currentColor"
        d="M18.554 24.765a3.594 3.594 0 0 1-2.265-.82 3.526 3.526 0 0 1-.43-4.961C24.492 8.749 34.65 6.835 35.078 6.757a3.48 3.48 0 0 1 4.062 2.852c.352 1.914-.937 3.71-2.812 4.062-.196.04-8.242 1.68-15.117 9.805a3.363 3.363 0 0 1-2.657 1.289Z"
        opacity={0.35}
      />
      <path
        fill="#F7F7F7"
        d="M68.672 56.758c8.543 0 15.469-6.926 15.469-15.469S77.215 25.82 68.67 25.82c-8.542 0-15.468 6.926-15.468 15.47 0 8.542 6.926 15.468 15.469 15.468Z"
      />
      <path
        fill="#232323"
        d="M64.96 53.242a8.438 8.438 0 1 0 0-16.875 8.438 8.438 0 0 0 0 16.875Z"
      />
      <path
        fill="#F7F7F7"
        d="M31.328 56.758c8.543 0 15.469-6.926 15.469-15.469S39.87 25.82 31.328 25.82 15.86 32.746 15.86 41.29c0 8.542 6.926 15.468 15.47 15.468Z"
      />
      <path
        fill="#232323"
        d="M35.04 53.242a8.438 8.438 0 1 0 0-16.875 8.438 8.438 0 0 0 0 16.875Z"
      />
      <path
        fill="#191919"
        d="M17.93 25c-1.016 0-2.031-.04-3.086-.196a2.823 2.823 0 0 1-2.461-3.164 2.823 2.823 0 0 1 3.164-2.461c10.938 1.289 19.219-6.797 19.336-6.875 1.094-1.094 2.89-1.094 4.023 0 1.094 1.094 1.094 2.89 0 4.023C38.438 16.68 29.961 25 17.93 25Zm64.14 0c-12.03 0-20.507-8.282-20.898-8.673a2.84 2.84 0 0 1 0-4.023c1.094-1.094 2.89-1.133 3.984 0 .117.117 8.477 8.203 19.336 6.875 1.563-.195 2.969.937 3.164 2.46.196 1.563-.937 2.97-2.46 3.165-1.094.117-2.149.195-3.126.195Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SadIcon;
