import * as React from "react";
import { SVGProps } from "react";
const CupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={120} height={120} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FFB454"
        d="M60 97.406a3.516 3.516 0 0 1-3.516-3.516V75.063a3.516 3.516 0 0 1 7.032 0v18.829A3.516 3.516 0 0 1 60 97.406Z"
      />
      <path
        fill="#FFCB00"
        d="M41.172 67.281c-10.244 0-18.578-8.334-18.578-18.578v-7.531a3.516 3.516 0 0 1 3.515-3.516h15.063a3.516 3.516 0 0 1 0 7.032H29.625v4.015c0 6.367 5.18 11.547 11.547 11.547a3.516 3.516 0 0 1 0 7.031Z"
      />
      <path
        fill="#FFB454"
        d="M78.828 67.281a3.516 3.516 0 0 1 0-7.031c6.367 0 11.547-5.18 11.547-11.547v-4.016H78.828a3.516 3.516 0 0 1 0-7.03h15.063a3.516 3.516 0 0 1 3.515 3.515v7.531c0 10.244-8.334 18.578-18.578 18.578Z"
      />
      <path
        fill="#FFCB00"
        d="m98.865 16.165-5.515 5.514a3.516 3.516 0 0 0 4.972 4.972l5.514-5.515a3.515 3.515 0 0 0-4.971-4.971Z"
      />
      <path
        fill="#FFE705"
        d="m21.678 93.35-5.515 5.515a3.515 3.515 0 1 0 4.972 4.972l5.515-5.515a3.515 3.515 0 1 0-4.972-4.972Zm-.543-77.185a3.515 3.515 0 1 0-4.972 4.971l5.515 5.515a3.515 3.515 0 1 0 4.972-4.972l-5.515-5.514Z"
      />
      <path
        fill="#FFCB00"
        d="M98.322 93.35a3.516 3.516 0 0 0-4.972 4.971l5.515 5.515a3.515 3.515 0 1 0 4.971-4.971l-5.514-5.515ZM76.046.12a3.516 3.516 0 0 0-4.306 2.487L69.72 10.14a3.515 3.515 0 1 0 6.792 1.82l2.018-7.534A3.516 3.516 0 0 0 76.045.121Z"
      />
      <path
        fill="#FFE705"
        d="M47.793 105.554a3.515 3.515 0 0 0-4.306 2.486l-2.018 7.533a3.516 3.516 0 1 0 6.792 1.82l2.018-7.534a3.515 3.515 0 0 0-2.486-4.305ZM11.96 43.487l-7.534-2.019a3.515 3.515 0 1 0-1.82 6.792l7.534 2.019a3.515 3.515 0 1 0 1.82-6.792Z"
      />
      <path
        fill="#FFCB00"
        d="m117.393 71.739-7.533-2.019a3.516 3.516 0 0 0-1.82 6.792l7.534 2.019a3.515 3.515 0 1 0 1.819-6.792Z"
      />
      <path
        fill="#FFE705"
        d="M48.261 2.607a3.516 3.516 0 1 0-6.792 1.82l2.019 7.533a3.516 3.516 0 1 0 6.791-1.82l-2.018-7.533Z"
      />
      <path
        fill="#FFCB00"
        d="M76.512 108.04a3.515 3.515 0 1 0-6.792 1.819l2.019 7.534a3.516 3.516 0 0 0 6.791-1.82l-2.018-7.533Zm33.348-57.762 7.533-2.018a3.516 3.516 0 0 0-1.82-6.792l-7.533 2.02a3.515 3.515 0 1 0 1.82 6.79Z"
      />
      <path
        fill="#FFE705"
        d="M10.14 69.721 2.607 71.74a3.515 3.515 0 1 0 1.82 6.791l7.533-2.019a3.516 3.516 0 1 0-1.82-6.791ZM60 78.578c-12.32 0-22.344-10.023-22.344-22.344V33.641a3.516 3.516 0 0 1 3.516-3.516h37.656a3.516 3.516 0 0 1 3.516 3.516v22.593c0 12.32-10.024 22.344-22.344 22.344Zm11.297 18.828H48.703a3.516 3.516 0 0 1 0-7.03h22.594a3.516 3.516 0 0 1 0 7.03Z"
      />
      <path
        fill="#FFCB00"
        d="M82.344 56.234V33.641a3.516 3.516 0 0 0-3.516-3.516H60v48.453c12.32 0 22.344-10.023 22.344-22.344Zm-7.531 37.657a3.516 3.516 0 0 0-3.516-3.516H60v7.031h11.297a3.516 3.516 0 0 0 3.516-3.515Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CupIcon;
