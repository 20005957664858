import * as React from "react";
import { SVGProps } from "react";
const EvidenceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12.916 12.917 2.917 2.917M4.167 9.167a5 5 0 1 0 10 0 5 5 0 0 0-10 0Z"
    />
  </svg>
);
export default EvidenceIcon;
