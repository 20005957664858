import * as React from "react";
import { SVGProps } from "react";
const HeadphoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#57555C"
        d="M93.671 38.066c.235 1.582-.878 3.106-2.46 3.34h-.41a2.96 2.96 0 0 1-2.93-2.52C85.234 20.313 68.809 5.86 50 5.86S14.766 20.312 12.129 38.887c-.234 1.582-1.7 2.753-3.34 2.52-1.582-.235-2.696-1.759-2.46-3.34C9.374 16.561 28.26 0 49.998 0c21.74 0 40.626 16.563 43.672 38.066Z"
      />
      <path
        fill="#3C3A41"
        d="M93.672 38.066c.234 1.582-.879 3.106-2.461 3.34h-.41a2.96 2.96 0 0 1-2.93-2.52C85.234 20.313 68.81 5.86 50 5.86V0c21.739 0 40.625 16.563 43.672 38.066Z"
      />
      <path
        fill="#17ACE8"
        d="M50 23.828c-14.531 0-26.563 11.836-26.563 26.367 0 4.453 1.172 8.848 3.4 12.832l-3.224 9.668c-.351 1.055-.117 2.227.704 2.988.761.82 1.933 1.055 2.988.703l9.863-3.222c3.984 2.227 8.379 3.398 12.832 3.398 14.531 0 26.563-11.836 26.563-26.367 0-14.53-12.032-26.367-26.563-26.367Z"
      />
      <path
        fill="#1689FC"
        d="M76.563 50.195c0 14.531-12.032 26.367-26.563 26.367V23.828c14.531 0 26.563 11.836 26.563 26.367Z"
      />
      <path
        fill="#B7E0F6"
        d="M52.93 38.477a2.9 2.9 0 0 1-2.93 2.93 2.9 2.9 0 0 1-2.93-2.93c0-1.641 1.29-2.93 2.93-2.93 1.64 0 2.93 1.289 2.93 2.93Zm0 11.718v11.72c0 1.64-1.29 2.929-2.93 2.929a2.901 2.901 0 0 1-2.93-2.93V50.195c0-1.64 1.29-2.93 2.93-2.93 1.64 0 2.93 1.29 2.93 2.93Z"
      />
      <path
        fill="#A4D9F5"
        d="M50 41.406v-5.86c1.64 0 2.93 1.29 2.93 2.93a2.9 2.9 0 0 1-2.93 2.93Zm2.93 8.79v11.718c0 1.64-1.29 2.93-2.93 2.93V47.266c1.64 0 2.93 1.288 2.93 2.93Z"
      />
      <path
        fill="#FD3018"
        d="M14.648 76.563H8.79C3.943 76.563 0 72.62 0 67.773V44.336c0-4.846 3.943-8.79 8.79-8.79h5.858c1.62 0 2.93 1.311 2.93 2.93v35.157c0 1.62-1.31 2.93-2.93 2.93Z"
      />
      <path
        fill="#3C3A41"
        d="M73.633 94.14H55.859c-1.619 0-2.93-1.31-2.93-2.93 0-1.618 1.311-2.929 2.93-2.929h17.774c8.077 0 14.648-6.571 14.648-14.648 0-1.62 1.31-2.93 2.93-2.93s2.93 1.31 2.93 2.93c0 11.307-9.201 20.508-20.508 20.508Z"
      />
      <path
        fill="#E61E14"
        d="M91.21 76.563h-5.858c-1.62 0-2.93-1.31-2.93-2.93V38.477c0-1.62 1.31-2.93 2.93-2.93h5.859c4.846 0 8.789 3.943 8.789 8.789v23.437c0 4.847-3.943 8.79-8.79 8.79Z"
      />
      <path
        fill="#FD3018"
        d="M50 82.422a8.777 8.777 0 0 0-8.79 8.789A8.777 8.777 0 0 0 50 100a8.777 8.777 0 0 0 8.79-8.79A8.777 8.777 0 0 0 50 82.423Z"
      />
      <path
        fill="#E61E14"
        d="M58.79 91.21A8.777 8.777 0 0 1 50 100V82.422a8.777 8.777 0 0 1 8.79 8.789Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default HeadphoneIcon;
