import * as React from "react";
import { SVGProps } from "react";
const DonateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <path
      fill="#F6DCCD"
      fillRule="evenodd"
      d="m11.046 74.842 15.199-8.805c2.853-1.653 5.753-2.825 8.888-1.808 3.496 1.134 9.204 3.568 15.025 4.618 2.161.7 3.508 2.864 2.92 5.058-.588 2.194-2.442 3.325-5.057 2.92l2.035.326c2.331.373 4.473.054 6.514-1.135l14.613-8.511c11.36-6.616 13.027 3.562 9.25 5.887l-26.518 16.33c-2.44 1.501-5.108 1.852-7.875 1.11l-19.235-5.154-7.123 4.112a2.877 2.877 0 0 1-3.923-1.05L10 78.762a2.877 2.877 0 0 1 1.047-3.92Z"
      clipRule="evenodd"
    />
    <path
      fill="#F1CBBC"
      fillRule="evenodd"
      d="M52.732 74.815c-.813 1.587-2.486 2.355-4.712 2.01-3.15-.833-6.298-1.687-9.446-2.53a1.58 1.58 0 0 1 .818-3.054l13.34 3.574Zm-41.686.027 15.2-8.805c2.852-1.653 5.752-2.825 8.887-1.808 1.87.606 4.374 1.585 7.19 2.525L12.88 83.753l-2.881-4.99a2.877 2.877 0 0 1 1.047-3.92Z"
      clipRule="evenodd"
    />
    <path
      fill="#D1D1D6"
      fillRule="evenodd"
      d="m12.562 70.047 12.054 21.299a2.158 2.158 0 0 1-.813 2.932l-4.1 2.32a2.158 2.158 0 0 1-2.932-.812L4.717 74.487a2.158 2.158 0 0 1 .813-2.932l4.1-2.32a2.158 2.158 0 0 1 2.932.812Z"
      clipRule="evenodd"
    />
    <path
      fill="#B1B1B5"
      fillRule="evenodd"
      d="m18.901 84.68 2.134 3.77 2.11 3.728a2.158 2.158 0 0 1-.807 2.928l1.465-.828a2.158 2.158 0 0 0 .813-2.933l-2.117-3.74-4.251-7.51-4.255-7.52-1.431-2.528a2.16 2.16 0 0 0-2.506-.996l2.477 4.378 4.251 7.51 2.117 3.74v.001Z"
      clipRule="evenodd"
    />
    <path
      fill="#685E68"
      fillRule="evenodd"
      d="M9.806 73.23a1.463 1.463 0 1 0 .388 2.9 1.463 1.463 0 0 0-.388-2.9Z"
      clipRule="evenodd"
    />
    <path
      fill="#837683"
      fillRule="evenodd"
      d="M53.216 14.534h4.548V8.26c0-2.825-2.312-5.137-5.137-5.137H37.291c-2.826 0-5.138 2.312-5.138 5.137v6.275h4.548V8.26c0-.315.274-.59.59-.59h15.336c.315 0 .59.275.59.59v6.274Z"
      clipRule="evenodd"
    />
    <path
      fill="#685E68"
      fillRule="evenodd"
      d="M53.216 14.534h4.548-2.643V8.26a.603.603 0 0 0-.589-.589h-1.905c.315 0 .59.274.59.59v6.273Zm-14.02-11.41H37.29c-2.826-.001-5.138 2.31-5.138 5.136v6.275h1.905V8.26c0-2.825 2.312-5.137 5.138-5.137Z"
      clipRule="evenodd"
    />
    <path
      fill="#ECBA78"
      fillRule="evenodd"
      d="M15.085 29.014v25.635c0 2.64 2.16 4.8 4.8 4.8h50.148c2.64 0 4.8-2.16 4.8-4.8V29.015l-59.748-.001Z"
      clipRule="evenodd"
    />
    <path
      fill="#E3A76F"
      fillRule="evenodd"
      d="M15.085 29.014v25.635c0 2.64 2.16 4.8 4.8 4.8h3.81c-2.64 0-4.8-2.16-4.8-4.8V29.014h-3.81Z"
      clipRule="evenodd"
    />
    <path
      fill="#E3A76F"
      fillRule="evenodd"
      d="M73.339 34.143a14.96 14.96 0 0 0 1.494-1.07c1.727-1.418 2.924-3.188 2.924-5.512V15.858a2.465 2.465 0 0 0-2.46-2.46H14.621a2.464 2.464 0 0 0-2.46 2.46v11.703c0 2.323 1.197 4.093 2.924 5.51.464.382.967.738 1.494 1.072 9.987 6.318 46.846 6.272 56.76 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#C48958"
      fillRule="evenodd"
      d="M18.895 13.398H14.62a2.465 2.465 0 0 0-2.46 2.46v11.703c0 2.323 1.197 4.093 2.924 5.51.464.382.967.738 1.494 1.072.66.417 1.438.805 2.316 1.167V13.398Zm8.937 0h34.254v7.999a3.093 3.093 0 0 1-3.084 3.084H30.916a3.093 3.093 0 0 1-3.084-3.084v-7.999Z"
      clipRule="evenodd"
    />
    <path
      fill="#837683"
      fillRule="evenodd"
      d="M38.914 39.639a6.045 6.045 0 1 0 12.09 0 6.045 6.045 0 0 0-12.09 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#685E68"
      fillRule="evenodd"
      d="M38.914 39.639a6.045 6.045 0 0 0 6.998 5.97 6.047 6.047 0 0 1 0-11.94 6.045 6.045 0 0 0-6.998 5.97Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFE177"
      fillRule="evenodd"
      d="M80.9 23.7a15.625 15.625 0 0 1 4.574 1.896l2.973-1.32 3.503 3.503-1.32 2.972a15.62 15.62 0 0 1 1.897 4.576l3.033 1.167v4.954l-3.034 1.168a15.618 15.618 0 0 1-1.896 4.574l1.32 2.973-3.504 3.503-2.971-1.32a15.623 15.623 0 0 1-4.576 1.897l-1.167 3.033h-4.954l-1.168-3.034a15.62 15.62 0 0 1-4.575-1.896l-2.972 1.32-3.503-3.503 1.32-2.972a15.623 15.623 0 0 1-1.897-4.576l-3.033-1.167v-4.954l3.033-1.168a15.622 15.622 0 0 1 1.897-4.575l-1.32-2.972 3.503-3.503 2.972 1.32a15.627 15.627 0 0 1 4.575-1.897l1.167-3.033h4.955L80.9 23.7Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFD064"
      fillRule="evenodd"
      d="m69.036 52.348-2.973 1.32-.457-.458 2.787-1.238c.211.13.425.256.643.376ZM80.9 23.702a15.623 15.623 0 0 1 4.574 1.896l-.845.375c-1.414-.871-3.601-1.887-5.218-2.272l-1.167-3.033h1.488l1.168 3.034Zm4.575 28.646a15.617 15.617 0 0 1-4.575 1.897l-1.168 3.032h-1.488l1.167-3.032c1.617-.385 3.804-1.4 5.219-2.272l.845.375Zm-19.87-27.613.458-.457 2.972 1.32c-.217.12-.431.245-.643.375l-2.786-1.238Zm21.811 0 1.03-.457 3.504 3.503-1.32 2.972a15.623 15.623 0 0 1 1.897 4.575l3.032 1.167v4.955l-3.033 1.168a15.62 15.62 0 0 1-1.897 4.574l1.32 2.972-3.503 3.504-1.03-.458 3.046-3.046-1.32-2.972a15.626 15.626 0 0 0 1.896-4.574l3.033-1.168v-4.955l-3.032-1.167a15.623 15.623 0 0 0-1.897-4.576l1.32-2.97-3.046-3.047Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFC338"
      d="M83.72 46.872c4.37-3.585 5.007-10.034 1.422-14.404-3.584-4.37-10.033-5.008-14.403-1.423-4.37 3.584-5.008 10.033-1.423 14.404 3.584 4.37 10.033 5.007 14.403 1.423Z"
    />
    <path
      fill="#F5B829"
      fillRule="evenodd"
      d="M77.255 28.734c-5.654 0-10.237 4.583-10.237 10.238 0 5.654 4.583 10.237 10.237 10.237.274 0 .546-.011.814-.032-5.273-.415-9.423-4.825-9.423-10.205s4.15-9.79 9.423-10.205c-.27-.022-.542-.032-.814-.033Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFE177"
      fillRule="evenodd"
      d="M81.71 35.258a1.563 1.563 0 0 1-2.881 1.209 1.517 1.517 0 0 0-1.05-.904 2.064 2.064 0 0 0-.91-.024 1.634 1.634 0 0 0-.706.315.756.756 0 0 0-.294.637c.01.21.128.36.293.468.347.226.858.357 1.353.439.868.142 1.822.383 2.637.875.997.602 1.75 1.511 1.92 2.913.011.098.02.226.023.381.031 1.305-.56 2.274-1.45 2.949-.535.406-1.174.687-1.827.855v.08a1.562 1.562 0 0 1-3.125 0v-.112a5.03 5.03 0 0 1-1.394-.64 4.331 4.331 0 0 1-1.842-2.6 1.559 1.559 0 0 1 3.028-.745c.08.325.277.574.535.746.365.242.842.358 1.332.346.072-.002.176-.01.312-.027.422-.051.83-.176 1.101-.381.133-.1.22-.233.217-.397l-.011-.089c-.034-.276-.203-.468-.43-.605-.41-.247-.983-.384-1.527-.473-.848-.14-1.774-.397-2.548-.903-.956-.624-1.643-1.55-1.71-2.933-.066-1.36.519-2.451 1.441-3.201a4.636 4.636 0 0 1 1.496-.802v-.14a1.562 1.562 0 1 1 3.125 0v.12a4.564 4.564 0 0 1 2.891 2.643Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DonateIcon;
