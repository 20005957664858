import * as React from "react";
import { SVGProps } from "react";
const ToureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <path
      fill="#8ACCE7"
      d="M95.313 50a45.34 45.34 0 0 1-61.94 42.164C16.574 85.535 4.688 69.154 4.688 50c0-18.941 11.62-35.168 28.125-41.937A45.157 45.157 0 0 1 50 4.688c1.566 0 3.13.08 4.688.24C77.51 7.273 95.311 26.557 95.311 50Z"
    />
    <path
      fill="#4BA477"
      d="m35.938 64.063 7.812 1.562v20.313l-10.377 6.226C16.574 85.535 4.688 69.154 4.688 50c0-18.941 11.62-35.168 28.125-41.938v7.563l12.5 12.5-4.688 10.938H29.687v7.812l10.938 1.563-1.563 6.25h-6.25l-1.562 4.687 4.688 4.688ZM95.311 50a45.195 45.195 0 0 1-14.89 33.582l-8.547-2.332-17.188-17.188V48.438l9.376-4.688v-12.5l-3.126-9.375-6.25-4.688V4.928C77.51 7.274 95.314 26.558 95.314 50Z"
    />
    <path
      fill="#26507A"
      d="M29.688 67.188h-18.75a3.125 3.125 0 0 0-3.126 3.124v21.876c0 1.725 1.4 3.124 3.125 3.124h18.75c1.726 0 3.125-1.399 3.125-3.124V70.311c0-1.725-1.399-3.124-3.124-3.124Z"
    />
    <path
      fill="#396795"
      d="M29.688 67.188h-12.5a3.125 3.125 0 0 0-3.125 3.124v21.876c0 1.725 1.399 3.124 3.124 3.124h12.5c1.726 0 3.125-1.399 3.125-3.124V70.311c0-1.725-1.399-3.124-3.124-3.124Z"
    />
    <path
      fill="#26507A"
      d="M17.188 71.875h3.125v18.75h-3.125v-18.75Zm4.687 0H25v18.75h-3.125v-18.75Zm4.688 0h3.125v18.75h-3.125v-18.75Z"
    />
    <path fill="#D3E1E9" d="M8.594 95.313a3.906 3.906 0 1 0 0-7.813 3.906 3.906 0 0 0 0 7.813Z" />
    <path
      fill="#395166"
      d="M8.594 96.875a5.469 5.469 0 1 1 0-10.938 5.469 5.469 0 0 1 0 10.938Zm0-7.813a2.344 2.344 0 1 0 0 4.689 2.344 2.344 0 0 0 0-4.689Z"
    />
    <path
      fill="#D3E1E9"
      d="M25 67.188h-3.125V56.25h1.563A1.563 1.563 0 0 1 25 57.813v9.374Zm-9.375 0H12.5v-9.376a1.563 1.563 0 0 1 1.563-1.562h1.562v10.938Z"
    />
    <path fill="#395166" d="M15.625 56.25h6.25v3.125h-6.25V56.25Z" />
    <path
      fill="#E44B4D"
      d="M18.75 50a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25ZM24.674 41.138l-2.352-2.058 2.157-2.465 2.352 2.058-2.157 2.465Zm4.292-4.615-2.006-2.397a19.078 19.078 0 0 1 2.934-2.021l1.525 2.728a15.92 15.92 0 0 0-2.453 1.69Zm37.086 8.762a19.092 19.092 0 0 1-3.524-.522l.745-3.035c.966.237 1.952.383 2.946.437l-.167 3.12Zm3.56-.143-.417-3.097a15.948 15.948 0 0 0 2.901-.672l.988 2.964c-1.13.376-2.292.646-3.472.805Zm-10.45-1.549a19.098 19.098 0 0 1-3.089-1.776l1.805-2.551a15.978 15.978 0 0 0 2.582 1.485l-1.298 2.842Zm17.182-.692-1.522-2.73c.868-.484 1.69-1.05 2.453-1.687l2.004 2.398a19.083 19.083 0 0 1-2.935 2.019ZM53.423 39.74l-2.558-2.047 1.952-2.44 2.558 2.046-1.952 2.44Zm-5.06-4.012a15.947 15.947 0 0 0-2.582-1.483l1.295-2.843a19.086 19.086 0 0 1 3.09 1.774l-1.803 2.552Zm-14.22-2.097-.989-2.965a19.082 19.082 0 0 1 3.47-.806l.42 3.097c-.986.133-1.957.359-2.901.674Zm8.824-.362a15.931 15.931 0 0 0-2.947-.434l.165-3.121c1.188.063 2.369.237 3.525.52l-.743 3.035Zm46.095-17.644a10.937 10.937 0 1 0-21.875 0c0 6.04 10.938 18.75 10.938 18.75s10.937-12.71 10.937-18.75Z"
    />
    <path fill="#D3E1E9" d="M78.125 21.875a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5Z" />
    <path fill="#395166" d="M70.313 32.813h15.624v3.124H70.314v-3.124Z" />
  </svg>
);
export default ToureIcon;
