const FinancialIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M11.691 2 3 6.185v2.177h18V6.173L11.691 2ZM19.8 7.162H4.2v-.223l7.509-3.616L19.8 6.95v.212ZM19.8 16.766H4.2v1.2h15.6v-1.2ZM21 18.86H3v1.2h18v-1.2ZM5.7 9.563H4.5v6h1.2v-6ZM19.5 9.563h-1.2v6h1.2v-6ZM15.9 9.563h-1.2v6h1.2v-6ZM9.3 9.563H8.1v6h1.2v-6ZM12.6 9.563h-1.2v6h1.2v-6Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FinancialIcon;
