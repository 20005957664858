import "./App.css";
import PageRoutes from "./routes/authPages";
import { useAppDispatch, useAppSelector } from "./store/store";
import SnackBarComponent from "./components/snackBar";
import { useEffect, useLayoutEffect, useState } from "react";
import { getAuthConfig } from "./store/thunk/authThunk";
import { Box, CircularProgress, Typography } from "@mui/material";
import { anonymousUser, generateGuid } from "./helper/service";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const dispatch = useAppDispatch();
  const { authConfig } = useAppSelector((state: any) => state.authReducer);
  const { progressData } = useAppSelector((state: any) => state.documentReducer);
  const [isLoading, setIsLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();

  useLayoutEffect(() => {
    if (window.top !== window.self) {
      sessionStorage.removeItem("Usapath-Emulate-UserName");
      sessionStorage.removeItem("Usapath-Etag");
      sessionStorage.removeItem("Usapath-Session-Id");
      sessionStorage.removeItem("unSavedFormValues");

      localStorage.removeItem("isSessionCleared");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("lawyerData");
      localStorage.removeItem("role");
      localStorage.removeItem("userType");
      localStorage.removeItem("bootstrapData");
      localStorage.removeItem("isBootstrapCalled");
      localStorage.removeItem("userData");
      localStorage.removeItem("intercom.intercom-state-ka1bqnu1");
    }
  }, []);

  useEffect(() => {
    {
      /* ----- Update sessionId when duplicate the website ----- */
    }
    {
      /* ----- Disclaimer: Below code is when click on back/forward button of browser then also update the sessionId ----- */
    }

    var navigationEntries = window.performance.getEntriesByType("navigation");

    if (navigationEntries.length > 0) {
      var navigationType = navigationEntries[0] as PerformanceNavigationTiming;

      // back_forward value indicates a duplicate tab
      if (navigationType.type === "back_forward") {
        let sessionID = sessionStorage.getItem("Usapath-Session-Id");
        sessionID = generateGuid();
        sessionStorage.setItem("Usapath-Session-Id", sessionID);
      }
    }

    setIsLoading(true);
    dispatch(getAuthConfig()).then((res: any) => {
      setIsLoading(false);
      localStorage.setItem("isSessionCleared", "true");
    });
  }, []);

  {
    /* ----- On refresh update session id  ----- */
  }
  // useEffect(() => {
  //   const tabsOpen = localStorage.getItem("tabsOpen");
  //   let sessionID = sessionStorage.getItem("Usapath-Session-Id");

  //   if (tabsOpen == null) {
  //     localStorage.setItem("tabsOpen", "1");
  //   } else {
  //     sessionID = generateGuid();
  //     sessionStorage.setItem("Usapath-Session-Id", sessionID);
  //     localStorage.setItem(
  //       "tabsOpen",
  //       (parseInt(tabsOpen) + parseInt("1")).toString()
  //     );
  //   }

  //   // define decrement counter part
  //   window.onunload = function (e) {
  //     const newTabCount = localStorage.getItem("tabsOpen");
  //     if (newTabCount !== null) {
  //       localStorage.setItem(
  //         "tabsOpen",
  //         (parseInt(newTabCount) - 1).toString()
  //       );
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : null;
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData") || "{}")
      : null;
    if (authConfig?.intercomData?.appId) {
      if ((accessToken && userData && Object.keys(userData).length > 0) || anonymousUser()) {
        let general_eligibility_percentage;
        let getting_to_know_you_percentage;
        let disclaimer_percentage;
        let employment_info_percentage;
        let evidence_engine_percentage;
        let financial_info_percentage;
        let immigration_travel_percentage;
        let special_forms_percentage;
        let miscllaneous_percentage;
        let printsign_percentage;
        let personal_information_percentage;

        if (progressData && progressData?.length) {
          progressData?.length &&
            progressData?.map((data: any, index: number) => {
              if (data?.sectionKey === "General_Eligibility") {
                general_eligibility_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Getting_to_Know_you") {
                getting_to_know_you_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Disclaimer") {
                disclaimer_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "EmploymentInfo") {
                employment_info_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Evidence_Engine") {
                evidence_engine_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Financial_Info") {
                financial_info_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Immigration&Travel") {
                immigration_travel_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Special_Forms") {
                special_forms_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Miscllaneous") {
                miscllaneous_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Printsign") {
                printsign_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Personal_Information") {
                personal_information_percentage = data?.percentComplete;
              }
            });
        }
        // Update window.intercomSettings with user data
        (window as any).intercomSettings = {
          ...(window as any).intercomSettings,
          ...{
            email: userData?.email,
            name: userData?.name,
            progress_section_general_eligibility: general_eligibility_percentage,
            progress_section_getting_to_know_you: getting_to_know_you_percentage,
            progress_section_disclaimer: disclaimer_percentage,
            progress_section_employment_info: employment_info_percentage,
            progress_section_evidence_engine: evidence_engine_percentage,
            progress_section_financial_info: financial_info_percentage,
            progress_section_immigration_travel: immigration_travel_percentage,
            progress_section_special_forms: special_forms_percentage,
            progress_section_miscllaneous: miscllaneous_percentage,
            progress_section_printsign: printsign_percentage,
            progress_section_personal_information: personal_information_percentage,

            // Add any other user properties here
          },
          app_id: authConfig?.intercomData?.appId,
        };

        // Load the Intercom script
        (function () {
          if (window.self !== window.top) {
            return;
          }
          var w = window as any;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            // ic("reattach_activator");
            ic("update", (window as any).intercomSettings);
          } else {
            var d: any = document;
            var i: any = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args: any) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = `https://widget.intercom.io/widget/${authConfig?.intercomData?.appId}`;
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }
    }
  }, [authConfig, progressData]);

  if (isLoading) {
    return (
      <div className="flex h-screen justify-center items-center">
        <CircularProgress size={24} />
      </div>
    );
  } else {
    if (window.self === window.top)
      return (
        <Box
          sx={{
            position: "fixed",
            inset: 0,
            display: "grid",
            placeContent: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <img
              src={require("./assets/images/formloge-logo.png")}
              width={100}
              style={{ translate: "0 -9px" }}
            />
            <Typography variant="h2" sx={{ lineHeight: 1, fontWeight: 600 }}>
              Formloge.
            </Typography>
          </Box>
          <Typography variant="h6">Form Intensive ERPs that actually work.</Typography>
          <Typography variant="inherit" sx={{ fontSize: 16, mt: 3, maxWidth: 470 }}>
            Visit{" "}
            <a href="https://www.formloge.com" style={{ color: "#1976d2" }}>
              https://www.formloge.com
            </a>{" "}
            to learn how we can help your business optiomize form filling.
          </Typography>
        </Box>
      );
    return (
      <div>
        <PageRoutes />
        <SnackBarComponent />
      </div>
    );
  }
}

export default App;
