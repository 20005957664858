import * as React from "react";
import { SVGProps } from "react";
const EmploymentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99591 14.5243C9.65091 14.5243 9.37091 14.2443 9.37091 13.8993V11.7852C9.37091 11.4402 9.65091 11.1602 9.99591 11.1602C10.3409 11.1602 10.6209 11.4402 10.6209 11.7852V13.8993C10.6209 14.2443 10.3409 14.5243 9.99591 14.5243Z"
      fill="currentColor"
    />
    <mask
      id="mask0_2269_34857"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="3"
      width="18"
      height="10"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66681 3.81641H18.325V12.4089H1.66681V3.81641Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2269_34857)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.91681 9.49391C4.89681 10.5697 7.39348 11.1589 9.99181 11.1589C12.5951 11.1589 15.0943 10.5697 17.0751 9.49391V6.99224C17.0751 5.92974 16.216 5.06641 15.1585 5.06641H4.84181C3.78014 5.06641 2.91681 5.92641 2.91681 6.98391V9.49391ZM9.99181 12.4089C7.03764 12.4089 4.19014 11.6939 1.97598 10.3972C1.78431 10.2856 1.66681 10.0806 1.66681 9.85807V6.98391C1.66681 5.23724 3.09098 3.81641 4.84181 3.81641H15.1585C16.9051 3.81641 18.3251 5.24057 18.3251 6.99224V9.85807C18.3251 10.0806 18.2068 10.2856 18.016 10.3972C15.8018 11.6939 12.9518 12.4089 9.99181 12.4089Z"
        fill="currentColor"
      />
    </g>
    <mask
      id="mask1_2269_34857"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="1"
      width="8"
      height="5"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.45422 1.66797H13.5376V5.06455H6.45422V1.66797Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_2269_34857)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9126 5.06464C12.5676 5.06464 12.2876 4.78464 12.2876 4.43964V4.13464C12.2876 3.4638 11.7417 2.91797 11.0709 2.91797H8.92089C8.25006 2.91797 7.70422 3.4638 7.70422 4.13464V4.43964C7.70422 4.78464 7.42422 5.06464 7.07922 5.06464C6.73422 5.06464 6.45422 4.78464 6.45422 4.43964V4.13464C6.45422 2.77464 7.56089 1.66797 8.92089 1.66797H11.0709C12.4309 1.66797 13.5376 2.77464 13.5376 4.13464V4.43964C13.5376 4.78464 13.2576 5.06464 12.9126 5.06464Z"
        fill="currentColor"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8291 18.1193H5.16241C3.43324 18.1193 1.97741 16.7684 1.84658 15.0434L1.68824 12.9526C1.66241 12.6084 1.92074 12.3076 2.26491 12.2818C2.61074 12.2693 2.90908 12.5134 2.93574 12.8584L3.09324 14.9484C3.17491 16.0251 4.08324 16.8693 5.16241 16.8693H14.8291C15.9082 16.8693 16.8174 16.0251 16.8982 14.9484L17.0566 12.8584C17.0832 12.5134 17.3891 12.2684 17.7274 12.2818C18.0716 12.3076 18.3291 12.6084 18.3032 12.9526L18.1449 15.0434C18.0141 16.7684 16.5582 18.1193 14.8291 18.1193Z"
      fill="currentColor"
    />
  </svg>
);
export default EmploymentIcon;
