import * as React from "react";
import { SVGProps } from "react";
const MoneyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FDF6EC"
        d="M96.082 0h-71.6a1.006 1.006 0 0 0-1.005 1.006v47.307h5.633c.656 0 1.245.34 1.573.908a1.798 1.798 0 0 1 0 1.817l-4.458 7.722h2.454c1.002 0 1.817.815 1.817 1.817v2.92a1.819 1.819 0 0 1-1.817 1.817h-.731l-.062.38c4.27 1.433 7.915 3.913 10.543 7.172 2.685 3.33 4.104 7.242 4.104 11.314 0 6.161-3.233 11.868-8.87 15.658l-.086.058V100h62.505a1.007 1.007 0 0 0 1.006-1.006V1.006A1.006 1.006 0 0 0 96.082 0Z"
      />
      <path
        fill="#FD5426"
        d="M85.35 84.027h-9.288l-.196.296v5.556l.195.452h9.289a3.152 3.152 0 0 0 0-6.304Z"
      />
      <path
        fill="#FEF1DA"
        d="M29.11 48.118c1.548 0 2.517 1.676 1.742 3.018l-4.29 7.429h2.117c1.111 0 2.012.9 2.012 2.012v2.92c0 1.112-.9 2.012-2.012 2.012h-.731a24.59 24.59 0 0 1 4.176 1.841V1.006A1.005 1.005 0 0 1 33.13 0h-8.647a1.006 1.006 0 0 0-1.006 1.006v47.112h5.633Z"
      />
      <path
        fill="#FED402"
        d="M29.11 48.117H16.531c-1.55 0-2.517 1.677-1.743 3.018l4.402 7.625h7.259l4.402-7.625c.774-1.341-.194-3.018-1.743-3.018Z"
      />
      <path
        fill="#FAC600"
        d="m25.19 58.76-4.402-7.625c-.774-1.341.194-3.018 1.742-3.018h-5.998c-1.55 0-2.517 1.677-1.743 3.018l4.402 7.625h5.999Z"
      />
      <path
        fill="#FED402"
        d="M27.35 65.314h-9.06C9.33 68.078 2.913 75.48 2.913 84.181c0 6.106 3.16 11.572 8.14 15.245a2.986 2.986 0 0 0 1.773.575h20.946c5.45-3.664 8.956-9.388 8.956-15.82 0-8.7-6.416-16.104-15.377-18.867Z"
      />
      <path
        fill="#FAC600"
        d="M11.368 84.18c0-8.704 6.424-16.106 15.392-18.866h-8.47C9.33 68.078 2.913 75.48 2.913 84.181c0 6.106 3.16 11.572 8.14 15.245a2.986 2.986 0 0 0 1.773.575h8.456c-.637 0-1.26-.197-1.773-.575-4.981-3.673-8.14-9.14-8.14-15.245Z"
      />
      <path
        fill="#0ED290"
        d="M24.551 80.635h-3.46a1.668 1.668 0 0 1-1.666-1.666c0-.919.747-1.666 1.666-1.666h6.097a1.465 1.465 0 1 0 0-2.93h-2.902v-1.748a1.465 1.465 0 1 0-2.93 0v1.748h-.265a4.6 4.6 0 0 0-4.596 4.596 4.601 4.601 0 0 0 4.596 4.596h3.46c.919 0 1.666.747 1.666 1.666 0 .919-.747 1.666-1.666 1.666h-6.097a1.465 1.465 0 1 0 0 2.93h2.902v1.748a1.465 1.465 0 1 0 2.93 0v-1.748h.265a4.6 4.6 0 0 0 4.596-4.596 4.6 4.6 0 0 0-4.596-4.596Z"
      />
      <path
        fill="#05E39C"
        d="M28.679 65.509H16.962c-1.111 0-2.012-.9-2.012-2.012v-2.92c0-1.112.901-2.012 2.012-2.012H28.68c1.111 0 2.012.9 2.012 2.012v2.92c0 1.111-.9 2.012-2.012 2.012Z"
      />
      <path
        fill="#0ED290"
        d="M21.785 63.497v-2.92c0-1.112.9-2.012 2.012-2.012h-6.835c-1.111 0-2.012.9-2.012 2.012v2.92c0 1.111.901 2.012 2.012 2.012h6.835a2.012 2.012 0 0 1-2.012-2.012Z"
      />
      <path
        fill="#FD5426"
        d="M36.053 41.202V30.32a.605.605 0 0 0-.604-.604h-3.823a.603.603 0 0 0-.604.604v10.883h5.03Z"
      />
      <path
        fill="#EF4424"
        d="M33.66 29.715h-2.034a.603.603 0 0 0-.604.604v10.883h2.034V30.32a.604.604 0 0 1 .604-.604Z"
      />
      <path
        fill="#FED402"
        d="M46.751 41.203V25.238a.604.604 0 0 0-.604-.603h-3.823a.604.604 0 0 0-.603.603v15.965h5.03Z"
      />
      <path
        fill="#FAC600"
        d="M44.55 24.635h-2.226a.604.604 0 0 0-.603.603v15.965h2.225V25.238a.605.605 0 0 1 .604-.603Z"
      />
      <path
        fill="#01D0FB"
        d="M57.448 41.203V20.268a.604.604 0 0 0-.604-.604h-3.822a.604.604 0 0 0-.604.604v20.935h5.03Z"
      />
      <path
        fill="#01C0FA"
        d="M55.096 19.664h-2.074a.604.604 0 0 0-.604.604v20.935h2.074V20.268a.604.604 0 0 1 .604-.604Z"
      />
      <path
        fill="#05E39C"
        d="M68.145 41.203V15.88a.604.604 0 0 0-.603-.604h-3.823a.603.603 0 0 0-.604.604v25.322h5.03Z"
      />
      <path
        fill="#0ED290"
        d="M65.886 15.277h-2.167a.603.603 0 0 0-.604.604v25.322h2.167V15.88a.604.604 0 0 1 .604-.604Z"
      />
      <path
        fill="#01D0FB"
        d="M78.844 41.203V21.145a.604.604 0 0 0-.604-.604h-3.823a.604.604 0 0 0-.603.604v20.058h5.03Z"
      />
      <path
        fill="#01C0FA"
        d="M76.58 20.541h-2.163a.604.604 0 0 0-.603.604v20.058h2.163V21.145a.604.604 0 0 1 .604-.604Z"
      />
      <path
        fill="#FED402"
        d="M89.541 41.203v-13.98a.604.604 0 0 0-.604-.604h-3.822a.603.603 0 0 0-.604.604v13.98h5.03Z"
      />
      <path
        fill="#FAC600"
        d="M87.175 26.62h-2.06a.603.603 0 0 0-.604.603v13.98h2.06v-13.98a.604.604 0 0 1 .604-.604Z"
      />
      <path
        fill="#8EBAC5"
        d="M89.746 43.938H30.86a1.465 1.465 0 1 1 0-2.93h58.887a1.465 1.465 0 1 1 0 2.93Z"
      />
      <path
        fill="#FD5426"
        d="M75.717 63.365h10.627c.001-.067.005-.133.005-.2 0-5.818-4.732-10.55-10.549-10.55a10.516 10.516 0 0 0-7.599 3.244l7.516 7.506Z"
      />
      <path
        fill="#EF4424"
        d="M71.79 59.444c1.84-3.104 5.22-5.19 9.082-5.19.197 0 .395.006.592.018A10.516 10.516 0 0 0 68.2 55.859l3.59 3.585Z"
      />
      <path
        fill="#01D0FB"
        d="M75.8 63.165h12.1c.334 0 .603.279.587.612-.324 6.689-5.924 12.09-12.687 12.09-6.968 0-12.701-5.734-12.701-12.702 0-3.153 1.173-6.193 3.29-8.53a.586.586 0 0 1 .85-.02l8.561 8.55Z"
      />
      <path
        fill="#01C0FA"
        d="M68.26 63.166c0-2.1.521-4.168 1.516-6.017l-2.537-2.534a.589.589 0 0 0-.85.02 12.708 12.708 0 0 0-3.29 8.53c0 6.968 5.733 12.702 12.701 12.702.883 0 1.746-.093 2.58-.268-5.748-1.206-10.12-6.349-10.12-12.434Z"
      />
      <path
        fill="#D8AA5E"
        d="M57.774 55.95H40.876a1.465 1.465 0 1 1 0-2.93h16.898a1.465 1.465 0 1 1 0 2.93Zm0 5.787H40.876a1.465 1.465 0 1 1 0-2.93h16.898a1.465 1.465 0 1 1 0 2.93Zm0 5.787H43.191a1.465 1.465 0 1 1 0-2.93h14.583a1.465 1.465 0 1 1 0 2.93Zm0 5.787H47.82a1.465 1.465 0 1 1 0-2.93h9.954a1.465 1.465 0 1 1 0 2.93ZM48.052 8.873H31.106a1.465 1.465 0 1 1 0-2.93h16.946a1.465 1.465 0 1 1 0 2.93Zm41.405 0h-9.306a1.465 1.465 0 1 1 0-2.93h9.306a1.465 1.465 0 1 1 0 2.93Zm0 5.324h-9.306a1.465 1.465 0 1 1 0-2.93h9.306a1.465 1.465 0 1 1 0 2.93Z"
      />
      <path fill="#01D0FB" d="M52.6 84.027a3.152 3.152 0 0 0 0 6.304h23.462v-6.304H52.599Z" />
      <path
        fill="#01C0FA"
        d="M57.994 87.18a3.152 3.152 0 0 1 3.152-3.153h-8.547a3.152 3.152 0 0 0 0 6.304h8.547a3.152 3.152 0 0 1-3.152-3.152Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default MoneyIcon;
