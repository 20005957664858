import * as React from "react";
import { SVGProps } from "react";
const FamilyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#7F7369"
        d="M64.654 48.24h-8.816a2.433 2.433 0 0 1-2.426-2.427l-1.213-20.56s-1.963-17.03 8.219-19.268c0 0 4.14-8.056 17.4-5.475.18.035.355.1.53.155a20.157 20.157 0 0 1 8.256 4.972c7.22 7.186 5.768 15.675 5.95 16.73 0 5.898.093-.657-1.214 23.446a2.433 2.433 0 0 1-2.426 2.426h-9.379"
      />
      <path
        fill="#72655A"
        d="M66.186 48.24v-2.886c-5.45-2.408-8.847-7.562-9.133-13.211-.014-.26-.021-.52-.022-.78.003-9.078-.005-8.527.007-8.676-.091-4.845.659-15.009 8.364-16.702 0 0 2.537-4.936 9.999-5.843-5.685-.625-12.354 1.065-14.983 5.843-6.871 1.51-8.524 9.876-8.361 16.87l.007.286c.04 1.297.135 2.112.135 2.112l1.213 20.56a2.433 2.433 0 0 0 2.426 2.426h10.348Z"
      />
      <path
        fill="#DD9A5E"
        d="M44.581 93.215 47.19 69.91c.914-7.038.539-9.741 2.81-12.497-.702-.853-1.703-1.648-2.363-2.051l-.073-.018c-2.455-1.47-4.523-1.533-9.085-2.788l-4.666-2.233v-5.574c-3.91 1.729-8.405 1.668-12.168.032l-.02 5.565-4.646 2.21c-4.534 1.248-6.625 1.314-9.086 2.788l-.072.018a6.52 6.52 0 0 0-.642.45c-3.516 2.524-3.571 5.803-3.874 8.226L1.909 76.514C-.058 94.373 0 92.936 0 94.313-.029 96.58 1.361 100 5.585 100c.392 0 44.081.002 44.415-.003-3.784-.063-5.75-2.95-5.419-6.782Z"
      />
      <path
        fill="#D38D54"
        d="M5.032 94.305c-.034-1.97.378-1.948 3.462-33.902.323-2.643 1.124-4.803 3.43-6.59-.35.11-2.114.381-4.03 1.53l-.073.017a6.546 6.546 0 0 0-.642.45c-3.515 2.524-3.571 5.803-3.874 8.226-.025.22-3.304 29.31-3.304 30.275-.03 2.267 1.36 5.689 5.584 5.689h5.03c-3.623 0-5.647-2.726-5.583-5.695Zm21.625-39.202v-9.115a15.132 15.132 0 0 1-5.012-1.209l-.02 5.565 5.032 4.76Z"
      />
      <path
        fill="#EAB883"
        d="M99.96 93.214C96.407 61.75 96.607 62.639 96.289 61.1c-.517-2.496-2.021-4.466-4.11-5.741l-.073-.018a12.41 12.41 0 0 0-2.944-1.26l-10.244-2.55c-.333-.15-.564-.646-.564-1.212v-4.94c-3.86 1.683-8.36 1.656-12.167-.027v4.968c0 .565-.23 1.06-.564 1.212-8.666 2.308-10.593 2.254-13.188 3.809l-.073.018c-2.866 1.75-3.974 4.123-4.357 7.256l-3.424 30.598c-.343 3.967 1.76 6.785 5.546 6.785h44.287c4.224 0 5.615-3.422 5.584-5.688v-.007c.006-.364-.007-.728-.038-1.09Z"
      />
      <path
        fill="#D8A373"
        d="M49.573 94.311v-.007c-.005-.363.007-.727.038-1.09l3.425-32.408c.366-3.002 1.388-5.373 4.271-7.203-.846.257-2.776.483-4.872 1.74l-.073.017c-2.867 1.751-3.975 4.124-4.357 7.256l-3.424 30.599c-.343 3.966 1.76 6.784 5.546 6.784h5.03c-4.224 0-5.614-3.421-5.584-5.688Z"
      />
      <path
        fill="#D83D3D"
        d="M98.09 76.51c-1.614-14.334-1.544-14.168-1.8-15.409-.52-2.51-2.041-4.478-4.11-5.741l-.073-.018a12.406 12.406 0 0 0-2.945-1.26l-10.244-2.55c-.333-.15-.563-.646-.563-1.212v-4.967a15.161 15.161 0 0 1-12.168 0v4.967c0 .566-.23 1.062-.563 1.212-8.666 2.309-10.593 2.255-13.189 3.81l-.072.018c-2.841 1.734-3.975 4.128-4.357 7.256l-1.555 13.893 9.8 1.028a.986.986 0 0 1 .883 1.031l-1.077 21.43h32.487l-1.129-21.347a1.072 1.072 0 0 1 .958-1.122l9.718-1.02Z"
      />
      <path
        fill="#A82626"
        d="m46.451 76.51 4.872.511 1.713-16.215c.366-3.001 1.389-5.373 4.272-7.203-1.218.327-2.991.613-4.873 1.74l-.072.017c-2.097 1.28-3.596 3.255-4.11 5.742-.316 1.525-.256 1.858-1.802 15.408Z"
      />
      <path
        fill="#70BBEF"
        d="m47.6 55.36-.072-.018c-2.46-1.475-4.561-1.544-9.054-2.78l.005-.008-4.666-2.234-.034.051-.001-.018-6.086 5.73-6.067-5.74-4.646 2.21c-4.534 1.248-6.626 1.315-9.086 2.789l-.073.018c-2.867 1.75-3.974 4.124-4.357 7.256L1.908 76.513l9.613 1.009a1.147 1.147 0 0 1 1.026 1.198L11.48 100h32.486l-1.128-21.345a1.075 1.075 0 0 1 .96-1.126l2.57-.269c1.884-15.63 1.333-17.032 3.615-19.826-.5-.613-1.835-1.74-2.381-2.073Z"
      />
      <path
        fill="#4CAEE5"
        d="M8.495 60.403c.322-2.644 1.124-4.803 3.43-6.59-.457.13-2.175.417-4.031 1.529l-.073.018c-.68.415-1.693 1.23-2.381 2.073-2.436 2.982-1.773 5.333-3.53 19.08l4.971.522c-.015.158.2-1.983 1.614-16.632Z"
      />
      <path
        fill="#EAB883"
        d="m83.02 52.554-4.102-1.021c-.333-.151-.564-.647-.564-1.212V44.75c-3.924 1.734-8.407 1.661-12.167 0v5.57c0 .566-.23 1.062-.564 1.213l-4.164 1.036c5.005 8.418 16.56 8.421 21.561-.015Z"
      />
      <path
        fill="#4C4846"
        d="M8.621 24.316v-5.245c0-15.438 17.453-24.37 29.975-15.647 9.822 6.858 7.951 16.942 8.167 20.892"
      />
      <path
        fill="#4C4846"
        d="M8.621 24.316v-5.245c0-15.438 17.453-24.37 29.975-15.647 9.822 6.858 7.951 16.942 8.167 20.892"
      />
      <path
        fill="#3F3B39"
        d="M29.594.094C18.503-1.006 8.62 7.664 8.62 19.07v5.245h3.803V19.07c0-9.804 7.445-18.01 17.17-18.976Z"
      />
      <path
        fill="#DD9A5E"
        d="M47.712 26.022c0 2.198-2.127 3.98-4.752 3.98v-7.959c2.625 0 4.752 1.781 4.752 3.98Zm-40.04 0c0 2.198 2.127 3.98 4.752 3.98v-7.959c-2.625 0-4.752 1.781-4.752 3.98Z"
      />
      <path
        fill="#D38D54"
        d="M42.96 26.423V30c2.625 0 4.752-1.781 4.752-3.98 0-.643-.183-1.25-.507-1.788-.782 1.3-2.388 2.191-4.244 2.191Zm-34.78-2.19C6.602 26.85 8.864 30 12.423 30v-3.577c-1.856 0-3.462-.891-4.245-2.19Z"
      />
      <path
        fill="#EDAE6B"
        d="M39.443 16.513c-.056-1.525-1.311-2.36-2.523-2.172-.283.044-.596.09-.796.118-6.02.854-11.742.8-17.659-.118-1.202-.188-2.468.635-2.522 2.172-.083 2.265-1.025 3.95-2.46 5-.598.437-1.039.586-1.059.597 0 9.884-.118 10.544.412 12.783a15.193 15.193 0 0 0 2.67 5.67c7.105 9.395 21.978 7.56 26.389-3.584 1.46-3.688 1.066-6.487 1.066-14.869-.057-.042-3.36-1.213-3.518-5.597Z"
      />
      <path
        fill="#DD9A5E"
        d="M18.022 37.417c-1.078-3.843-.48-6.19-.648-13.474 0-.75.376-1.387.836-1.713 1.813-1.29 2.636-3.398 2.687-5.649a2.357 2.357 0 0 1 .896-1.814 62.555 62.555 0 0 1-3.328-.424c-1.2-.187-2.468.632-2.523 2.172-.083 2.261-1.023 3.949-2.46 5-.597.437-1.038.586-1.058.597 0 9.944-.006 9.43.014 9.92.308 6.976 5.286 12.649 11.5 14.131-.29-.216-4.704-4.421-5.916-8.746Z"
      />
      <path
        fill="#4CAEE5"
        d="M29.202 67.05V54.663l-1.51 1.42-1.508-1.427V67.05a1.51 1.51 0 1 0 3.018 0Z"
      />
      <path
        fill="#DADADA"
        d="m33.813 50.32-6.12 5.762 3.303 3.33c.631.636 1.677.574 2.228-.132l5.255-6.726-4.666-2.234Z"
      />
      <path
        fill="#CECCCA"
        d="m36.162 51.445-2.35-1.125-6.12 5.762 2.187 2.203 6.283-6.84Zm-4.716 8.276c.273.122.577.159.872.106l-.872-.106Z"
      />
      <path
        fill="#DADADA"
        d="m21.625 50.344 6.067 5.739-3.303 3.33a1.488 1.488 0 0 1-2.228-.133l-5.255-6.726 4.719-2.21Z"
      />
      <path
        fill="#CECCCA"
        d="m23.415 56.06 2.091 2.226 2.187-2.203-6.068-5.74-2.487 1.166 4.277 4.551Z"
      />
      <path
        fill="#D8A373"
        d="M71.243 53.395v-7.41a15.067 15.067 0 0 1-5.056-1.235c-.153 4.4.398 6.347-.564 6.783l-4.164 1.036a13.218 13.218 0 0 0 4.09 4.303l4.324-1.59s1.37-.68 1.37-1.887Z"
      />
      <path
        fill="#F7CF9C"
        d="M86.032 26.368a1.61 1.61 0 0 1 1.536 1.608v1.154c-.004 2.05.139 4.041-.648 6.65-.793 2.629-2.287 5.044-4.532 7.041-9.471 8.435-24.69 2.068-25.335-10.678-.003-.057-.001.017-.009-.178-.008-.201-.012-.402-.013-.602v-8.519c0-.898.633-1.666 1.512-1.852 1.56-.33 4.08-1.226 6.365-3.659a1.392 1.392 0 0 1 2.088.068c2.218 2.687 8.208 8.461 19.036 8.967Z"
      />
      <path
        fill="#EAB883"
        d="M63.641 38.132c-2.016-4.074-1.548-6.962-1.58-12.625v-5.894a11.845 11.845 0 0 1-3.518 1.378 1.897 1.897 0 0 0-1.512 1.852v8.518c0 .253.014.646.014.67.001.02.022.371.036.549.704 8.962 8.968 15.292 17.734 13.84a15.278 15.278 0 0 1-11.174-8.288Z"
      />
      <path
        fill="#B72C2C"
        d="m85.964 53.287-2.944-.732c-2.253 3.802-6.24 6.33-10.785 6.33 0 0 13.79 13.792 13.729-5.598Z"
      />
      <path
        fill="#B72C2C"
        d="m58.572 53.287 2.943-.732c2.254 3.802 6.24 6.33 10.785 6.33 0 0-13.79 13.792-13.728-5.598Z"
      />
      <path
        fill="#A82626"
        d="M65.74 56.956c-2.433 1.295-5.236 1.913-7.032-.49 1.429 14.585 13.592 2.42 13.592 2.42-2.4 0-4.644-.705-6.56-1.93Z"
      />
      <path
        fill="#A82626"
        d="M78.795 56.956c2.433 1.295 5.236 1.913 7.032-.49-1.429 14.585-13.592 2.42-13.592 2.42 2.4 0 4.644-.705 6.56-1.93Z"
      />
      <path
        fill="#996123"
        d="M60.433 54.157c-5.784-7.292-16.955-6.5-21.76 1.272-2.81 4.542-1.75 8.68-1.983 9.947h26.62c-.084-2.6.633-6.798-2.877-11.22Z"
      />
      <path
        fill="#8E551C"
        d="M40.3 59.344c.06-.252.2-.75.207-.771 1.483-4.92 5.775-8.706 11.118-9.357a13.308 13.308 0 0 0-12.952 6.212c-2.812 4.543-1.75 8.68-1.984 9.947h3.252c.004-2.776-.103-4.072.36-6.03Z"
      />
      <path
        fill="#996123"
        d="M67.273 76.515c-1.17-3.628-2.686-5.893-3.842-7.074-.667.658-1.592 1.125-2.645 1.293 1.931 2.784 1.508 5.563 1.412 7.216a1.146 1.146 0 0 0 1.374 1.193l2.465-.511a1.648 1.648 0 0 0 1.236-2.117Z"
      />
      <path
        fill="#EFD0A3"
        d="M64.728 67.147c0 2.13-2.062 3.858-4.607 3.858v-7.716c2.545 0 4.607 1.727 4.607 3.858Z"
      />
      <path
        fill="#8E551C"
        d="M32.727 76.515c1.17-3.628 2.686-5.893 3.842-7.074.667.658 1.592 1.125 2.645 1.293-1.931 2.784-1.508 5.563-1.412 7.216a1.146 1.146 0 0 1-1.375 1.193l-2.464-.511a1.649 1.649 0 0 1-1.236-2.117Z"
      />
      <path
        fill="#EFD0A3"
        d="M35.272 67.147c0 2.13 2.062 3.858 4.607 3.858v-7.716c-2.545 0-4.607 1.727-4.607 3.858Z"
      />
      <path
        fill="#E2BF91"
        d="M60.12 67.7v3.304c3.414 0 5.598-2.982 4.164-5.51-.74 1.304-2.325 2.206-4.164 2.206Zm-24.406-2.206c-1.436 2.533.76 5.51 4.164 5.51V67.7c-1.838 0-3.424-.902-4.164-2.206Z"
      />
      <path
        fill="#EFD0A3"
        d="m67.115 100-1.05-9.38c-.24-1.964-.93-3.614-2.888-4.81l-.048-.011c-1.714-1.027-2.957-.984-8.742-2.525-.64-.29-.269-1.53-.374-4.683a10.129 10.129 0 0 1-8.065-.017c-.102 3.079.269 4.409-.374 4.7-5.774 1.538-7.026 1.497-8.742 2.525l-.048.012c-1.38.843-2.38 2.145-2.724 3.805-.205.99-.151 1.07-1.213 10.384h34.268Z"
      />
      <path
        fill="#F9B938"
        d="M32.846 100h34.268l-1.05-9.38c-.239-1.961-.933-3.616-2.888-4.81l-.048-.011c-1.699-1.018-3.068-1.02-7.655-2.255v1.214a5.462 5.462 0 0 1-.816 2.877l-.068.108a5.53 5.53 0 0 1-.462.61l-.024.027a4.812 4.812 0 0 1-.098.109l-.01.011-.003.002-.015.017-.118.12-.04.04c-.05.05-.103.099-.157.147l-.046.04c-.033.03-.068.059-.102.088l-.016.014-.062.05a5.515 5.515 0 0 1-.108.085l-.069.053-.161.115-.018.012c-.037.026-.075.05-.113.075l-.018.011a2.436 2.436 0 0 1-.15.093l-.032.02-.015.008-.042.025a5.257 5.257 0 0 1-.227.122l-.032.016a6.156 6.156 0 0 1-.129.063l-.07.032a6.644 6.644 0 0 1-.211.09 4.947 4.947 0 0 1-.34.124l-.066.02-.016.006-.036.01c-.036.012-.072.022-.108.032l-.053.015-.017.005a5.42 5.42 0 0 1-.218.053l-.032.007a5.492 5.492 0 0 1-.897.116l-.064.002-.035.002a4.292 4.292 0 0 1-.095.001h-.131l-.094-.001-.035-.002c-.022 0-.044 0-.065-.002a5.524 5.524 0 0 1-.897-.116l-.032-.007a5.596 5.596 0 0 1-.217-.053l-.018-.005-.053-.015a6.464 6.464 0 0 1-.106-.03c-.012-.005-.025-.008-.038-.012l-.016-.005a5.11 5.11 0 0 1-.616-.235l-.07-.032a4.397 4.397 0 0 1-.13-.063l-.032-.016-.009-.005-.026-.013a5.257 5.257 0 0 1-.191-.104l-.043-.025-.014-.009-.033-.02a4.588 4.588 0 0 1-.15-.092l-.017-.01a5.48 5.48 0 0 1-2.274-3.017c-.334-1.107-.203-1.896-.233-2.807l-1.162.29c-3.645.994-4.904 1-6.531 1.974l-.048.012c-1.363.832-2.38 2.137-2.725 3.806-.204.987-.163 1.18-1.212 10.383Z"
      />
      <path
        fill="#EAA224"
        d="M36.609 94.086c.42-3.783.125-6.958 3.308-9.001l.048-.013a7.634 7.634 0 0 1 1.13-.579c.073-.057.144-.11.212-.157-1.61.46-2.947.548-4.475 1.462l-.048.012c-1.39.85-2.39 2.16-2.732 3.847-.201.993-.155 1.13-1.205 10.343h3.133l.629-5.914Zm5.04 5.914.14-4.367a1.51 1.51 0 0 0-1.46-1.557 1.505 1.505 0 0 0-1.557 1.46L38.63 100h3.02Zm19.722 0-.143-4.464a1.503 1.503 0 0 0-1.557-1.46 1.51 1.51 0 0 0-1.46 1.557l.14 4.367h3.02Z"
      />
      <path
        fill="#E2BF91"
        d="M48.75 86.45c.024-.037 1.18-.379 1.233-1.841v-5.19a10.065 10.065 0 0 1-4.035-.845c-.102 3.08.269 4.409-.373 4.7l-1.049.26c.03.91-.101 1.698.233 2.808.142.472.367.951.588 1.301l3.402-1.193Z"
      />
      <path
        fill="#FCE3BB"
        d="M49.303 59.93c-2.271 3.778-7.093 3.197-8.805 2.851a.518.518 0 0 0-.619.508c0 6.148-.187 7.5.43 9.542 1.253 4.154 5.089 7.141 9.57 7.191 5.586.07 10.242-4.427 10.242-10.119V63.29a.518.518 0 0 0-.619-.508c-1.712.346-6.533.927-8.804-2.85a.813.813 0 0 0-1.395 0Z"
      />
      <path
        fill="#EFD0A3"
        d="M45.059 71.645c-.264-1.524-.094-2.112-.15-8.783-1.78.343-3.518.1-4.41-.08a.518.518 0 0 0-.62.508c0 6.24-.184 7.511.43 9.541 1.524 5.054 6.824 8.26 12.207 6.876a10.13 10.13 0 0 1-7.457-8.062Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FamilyIcon;
