import * as React from "react";
import { SVGProps } from "react";
const UploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={120} height={120} fill="none" {...props}>
    <path
      fill="#00ACEA"
      d="M56.36 19.022 53.49 0H11.376C5.035 0 0 5.167 0 11.507v79.635c0 6.34 5.035 11.402 11.377 11.402h39.947l28.78-43.575V27.894L56.36 19.022Z"
    />
    <path fill="#00EFD1" d="m53.49.025 26.536 27.868H62.36a8.864 8.864 0 0 1-8.872-8.872V.025Z" />
    <path
      fill="#083863"
      d="M52.472 59.751H18.474a2.618 2.618 0 0 0-2.61 2.61 2.617 2.617 0 0 0 2.61 2.608h33.998a2.617 2.617 0 0 0 2.61-2.608 2.6 2.6 0 0 0-2.61-2.61Zm-33.998-7.827h26.432a2.617 2.617 0 0 0 2.61-2.61 2.617 2.617 0 0 0-2.61-2.609H18.474a2.617 2.617 0 0 0-2.61 2.609 2.617 2.617 0 0 0 2.61 2.61Zm25.413 21.134H18.474a2.618 2.618 0 0 0-2.61 2.61 2.617 2.617 0 0 0 2.61 2.609h25.414a2.617 2.617 0 0 0 2.61-2.61 2.617 2.617 0 0 0-2.61-2.609Z"
    />
    <path
      fill="#FEDB41"
      d="M80.026 53.777c16.177 2.27 28.623 16.15 28.623 32.954 0 18.37-14.898 33.269-33.268 33.269-12.628 0-23.64-7.045-29.25-17.43a33.222 33.222 0 0 1-4.018-15.864c0-18.37 14.9-33.269 33.268-33.269 1.566.027 3.105.132 4.645.34Z"
    />
    <path
      fill="#083863"
      d="M77.234 67.476a2.627 2.627 0 0 0-1.904-.835c-.731 0-1.41.313-1.905.835L58.134 83.888c-.99 1.044-.913 2.714.131 3.679.495.47 1.148.705 1.775.705.704 0 1.434-.288 1.956-.835L72.8 75.877v28.494a2.617 2.617 0 0 0 2.608 2.61 2.618 2.618 0 0 0 2.61-2.61V75.877l10.724 11.56c.992 1.044 2.61 1.121 3.68.13a2.624 2.624 0 0 0 .13-3.679L77.234 67.476Z"
    />
  </svg>
);
export default UploadIcon;
