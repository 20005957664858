import * as React from "react";
import { SVGProps } from "react";
const EligibilityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.5 9.683 1.825 1.825L13.333 7.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10.833 17.1-.833.475-.833-.475A11.667 11.667 0 0 1 3.333 6.99a1.667 1.667 0 0 1 .834-1.441l5.066-2.925a1.525 1.525 0 0 1 1.534 0l5.066 2.925a1.666 1.666 0 0 1 .834 1.441A11.666 11.666 0 0 1 10.833 17.1Z"
    />
  </svg>
);
export default EligibilityIcon;
