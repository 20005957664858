import { createSlice } from "@reduxjs/toolkit";
import envConfig from "../../config/config";

const initialState = {
  userDetails: {},
  authConfig: {
    authData: {
      domain: "",
      audience: "",
      clientId: "",
    },
    stripeData: {
      productPaymentLink: "",
    },
    intercomData: {
      appId: "",
    },
  },
  verifyAuth: {},
  uiIncomeLimit: [],
};

export const authSlice = createSlice({
  name: "authuser",
  initialState,
  reducers: {
    // setUserDetails: (state, action) => ({
    //   ...state,
    //   userDetails: action.payload,
    // }),
    setAuthConfig: (state, action) => ({
      ...state,
      authConfig: action.payload,
    }),
    setVerifyAuth: (state, action) => ({
      ...state,
      verifyAuth: action.payload,
    }),
    setUiIncomeLimit: (state, action) => ({
      ...state,
      uiIncomeLimit: action.payload,
    }),
  },
});

export const { setAuthConfig, setVerifyAuth, setUiIncomeLimit } =
  authSlice.actions;
export default authSlice.reducer;
