import * as React from "react";
import { SVGProps } from "react";
const NaviconsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M16.25 7.416v6.667a2.5 2.5 0 0 1-2.5 2.5h-7.5a2.5 2.5 0 0 1-2.5-2.5V7.416" />
      <path
        strokeLinecap="round"
        d="m17.5 8.25-1.25-.832-5-3.334-.792-.525a.833.833 0 0 0-.916 0l-.792.525-5 3.334-1.25.833M8.333 14.084h3.334"
      />
    </g>
  </svg>
);
export default NaviconsIcon;
