import * as React from "react";

const BagIcon: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    fill="none"
    viewBox="0 0 100 100"
  >
    <path
      fill="#4671C6"
      d="M77.675 78.155H4.04a2.867 2.867 0 0 1-2.867-2.867V23.972a2.87 2.87 0 0 1 2.867-2.867h73.637a2.867 2.867 0 0 1 2.867 2.867v51.316a2.867 2.867 0 0 1-2.868 2.867"
    ></path>
    <path
      fill="#A4C9FF"
      d="M1.54 22.571 7.19 45.14a3.225 3.225 0 0 0 3.128 2.442h61.079a3.225 3.225 0 0 0 3.128-2.442l5.65-22.569a2.86 2.86 0 0 0-2.499-1.466H4.04a2.87 2.87 0 0 0-2.5 1.466"
    ></path>
    <path
      fill="#4671C6"
      d="m24.451 21.106 1.641-8.735a.72.72 0 0 1 .705-.585h28.12c.344 0 .64.246.704.585l1.64 8.735h4.376l-1.79-9.53a5.02 5.02 0 0 0-4.93-4.09h-28.12a5.02 5.02 0 0 0-4.931 4.091l-1.79 9.529z"
    ></path>
    <path
      fill="#3762CC"
      d="M77.676 78.936H4.039A3.65 3.65 0 0 1 .39 75.288V23.972a3.65 3.65 0 0 1 3.648-3.648h73.637a3.65 3.65 0 0 1 3.648 3.648v51.316a3.65 3.65 0 0 1-3.648 3.648M4.039 21.886a2.09 2.09 0 0 0-2.086 2.086v51.316a2.09 2.09 0 0 0 2.086 2.086h73.637a2.09 2.09 0 0 0 2.085-2.086V23.972a2.09 2.09 0 0 0-2.085-2.085z"
    ></path>
    <path
      fill="#3762CC"
      d="M71.396 48.363H10.318a4 4 0 0 1-3.886-3.033L.782 22.76a.78.78 0 0 1 .076-.571 3.65 3.65 0 0 1 3.181-1.865h73.637a3.66 3.66 0 0 1 3.181 1.864.78.78 0 0 1 .077.573L75.283 45.33a4 4 0 0 1-3.887 3.033M2.38 22.711l5.568 22.24a2.44 2.44 0 0 0 2.37 1.85h61.078a2.44 2.44 0 0 0 2.37-1.85l5.57-22.24a2.09 2.09 0 0 0-1.66-.824H4.039a2.09 2.09 0 0 0-1.66.824"
    ></path>
    <path
      fill="#3762CC"
      d="M61.637 21.887h-4.375a.78.78 0 0 1-.768-.637l-1.63-8.682H26.85l-1.63 8.682a.78.78 0 0 1-.769.637h-4.375a.782.782 0 0 1-.768-.925l1.79-9.53a5.8 5.8 0 0 1 5.699-4.727h28.12a5.8 5.8 0 0 1 5.698 4.727l1.79 9.53a.782.782 0 0 1-.768.925m-3.727-1.562h2.786l-1.617-8.604a4.24 4.24 0 0 0-4.162-3.453h-28.12a4.24 4.24 0 0 0-4.163 3.454l-1.616 8.603h2.786l1.52-8.098a1.5 1.5 0 0 1 1.473-1.221h28.12a1.5 1.5 0 0 1 1.473 1.22z"
    ></path>
    <path
      fill="#E0EBFC"
      d="M44.036 53.845h-6.358a1.79 1.79 0 0 1-1.791-1.792v-8.94a1.79 1.79 0 0 1 1.791-1.793h6.357a1.79 1.79 0 0 1 1.792 1.792v8.941a1.79 1.79 0 0 1-1.791 1.792"
    ></path>
    <path
      fill="#3762CC"
      d="M44.035 54.626h-6.357a2.576 2.576 0 0 1-2.572-2.573v-8.94a2.576 2.576 0 0 1 2.572-2.574h6.357a2.576 2.576 0 0 1 2.573 2.573v8.941a2.576 2.576 0 0 1-2.573 2.573m-6.357-12.524a1.01 1.01 0 0 0-1.01 1.01v8.941a1.01 1.01 0 0 0 1.01 1.01h6.357a1.01 1.01 0 0 0 1.01-1.01v-8.94a1.01 1.01 0 0 0-1.01-1.011z"
    ></path>
    <path
      fill="#E0EBFC"
      d="M96.094 94.14h-43.36A2.734 2.734 0 0 1 50 91.407v-55.86a2.734 2.734 0 0 1 2.734-2.733h43.36a2.734 2.734 0 0 1 2.734 2.734v55.86a2.734 2.734 0 0 1-2.734 2.734"
    ></path>
    <path
      fill="#6BDDDD"
      d="M73.353 71.46c8.621 0 15.61-6.989 15.61-15.61s-6.989-15.61-15.61-15.61-15.61 6.989-15.61 15.61 6.989 15.61 15.61 15.61"
    ></path>
    <path
      fill="#4671C6"
      d="M73.353 57.414a1.56 1.56 0 0 1-1.563-1.563v-9.859a1.563 1.563 0 0 1 3.125 0v9.86a1.56 1.56 0 0 1-1.562 1.562"
    ></path>
    <path
      fill="#4671C6"
      d="M79.834 57.412h-6.481a1.562 1.562 0 1 1 0-3.125h6.48a1.562 1.562 0 1 1 0 3.125"
    ></path>
    <path
      fill="#F9A7A7"
      d="m92.148 55.82-1.689-.332c-.194-9.27-7.79-16.754-17.107-16.754-9.438 0-17.116 7.679-17.116 17.116 0 9.438 7.679 17.116 17.116 17.116a17.1 17.1 0 0 0 11.183-4.158 1.507 1.507 0 0 0-1.457-2.57c-.188.063-.362.161-.512.29a14.1 14.1 0 0 1-9.214 3.426c-7.776 0-14.103-6.327-14.103-14.104s6.326-14.103 14.103-14.103c7.455 0 13.573 5.814 14.067 13.144l-1.296-.254a.548.548 0 0 0-.62.724l2.03 5.596a.547.547 0 0 0 .92.18l3.996-4.412a.548.548 0 0 0-.3-.905"
    ></path>
    <path
      fill="#A4C9FF"
      d="M91.927 81.61H56.901a1.4 1.4 0 1 1 0-2.797h35.026a1.4 1.4 0 1 1 0 2.798m0 6.644H56.901a1.4 1.4 0 1 1 0-2.798h35.026a1.4 1.4 0 1 1 0 2.798"
    ></path>
    <path
      fill="#3762CC"
      d="M96.094 94.922h-43.36a3.52 3.52 0 0 1-3.515-3.516v-55.86a3.52 3.52 0 0 1 3.515-3.515h43.36a3.52 3.52 0 0 1 3.515 3.516v55.86a3.52 3.52 0 0 1-3.515 3.515m-43.36-61.328a1.955 1.955 0 0 0-1.953 1.953v55.86c0 1.076.876 1.952 1.953 1.952h43.36a1.955 1.955 0 0 0 1.953-1.953v-55.86a1.955 1.955 0 0 0-1.953-1.952z"
    ></path>
    <path
      fill="#3762CC"
      d="M73.352 73.747c-9.868 0-17.897-8.028-17.897-17.897s8.029-17.897 17.897-17.897c9.45 0 17.334 7.519 17.863 16.888l1.083.212a1.33 1.33 0 0 1 .73 2.196l-3.996 4.413a1.32 1.32 0 0 1-1.242.412 1.32 1.32 0 0 1-.992-.851l-2.03-5.596a1.33 1.33 0 0 1 1.505-1.757l.258.05c-.943-6.466-6.544-11.393-13.18-11.393-7.345 0-13.321 5.977-13.321 13.323 0 7.345 5.976 13.321 13.322 13.321 3.195.001 6.284-1.147 8.703-3.235a2.29 2.29 0 0 1 3.226.236 2.29 2.29 0 0 1-.236 3.226 17.9 17.9 0 0 1-11.693 4.35m0-34.231c-9.007 0-16.334 7.327-16.334 16.334s7.327 16.335 16.334 16.335a16.33 16.33 0 0 0 10.672-3.969.726.726 0 0 0-.948-1.096 14.88 14.88 0 0 1-9.724 3.615c-8.208 0-14.885-6.678-14.885-14.885s6.677-14.884 14.885-14.884c7.8 0 14.321 6.093 14.846 13.873a.78.78 0 0 1-.93.82l-.89-.176 1.756 4.843 3.458-3.819-1.284-.252a.78.78 0 0 1-.63-.75c-.185-8.817-7.508-15.99-16.326-15.99"
    ></path>
  </svg>
);

export default BagIcon;
