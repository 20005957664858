import * as React from "react";

const AnalyticsIcon: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    fill="none"
    viewBox="0 0 100 100"
  >
    <path
      fill="#ECEFF1"
      fillRule="evenodd"
      d="M40.085 13.145c0-3.147 2.575-5.721 5.722-5.721h45.347c3.147 0 5.721 2.574 5.721 5.72v34.199c0 3.146-2.574 5.72-5.721 5.72H45.806c-3.146 0-5.721-2.574-5.721-5.72z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#D1D1D6"
      fillRule="evenodd"
      d="M93.01 7.736c2.243.776 3.865 2.913 3.865 5.41v34.198c0 3.146-2.574 5.72-5.72 5.72H45.806c-2.497 0-4.635-1.621-5.41-3.864a5.7 5.7 0 0 0 1.856.311H87.6c3.147 0 5.722-2.574 5.722-5.72V9.592a5.7 5.7 0 0 0-.311-1.857"
      clipRule="evenodd"
    ></path>
    <path
      fill="#A79BA7"
      fillRule="evenodd"
      d="M51.732 42.013a1.557 1.557 0 0 1-1.917-2.454l11.805-9.192a1.55 1.55 0 0 1 1.275-.297v-.002l11.04 2.318 11.214-10.03a1.556 1.556 0 1 1 2.075 2.319L75.486 35.173a1.56 1.56 0 0 1-1.42.426l-11.103-2.331z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FE646F"
      d="M50.773 44.203a3.418 3.418 0 1 0 0-6.836 3.418 3.418 0 0 0 0 6.836"
    ></path>
    <path
      fill="#FFE177"
      d="M63.116 34.977a3.418 3.418 0 1 0-1.099-6.747 3.418 3.418 0 0 0 1.099 6.747"
    ></path>
    <path
      fill="#6CF5C2"
      d="M74.383 37.49a3.418 3.418 0 1 0 0-6.836 3.418 3.418 0 0 0 0 6.836"
    ></path>
    <path
      fill="#8AC9FE"
      d="M88.591 25.938a3.418 3.418 0 1 0-4.833-4.833 3.418 3.418 0 0 0 4.833 4.833"
    ></path>
    <path
      fill="#FFE177"
      fillRule="evenodd"
      d="M64.4 72.443h-4.67a1.12 1.12 0 0 0-1.119 1.12v17.96c0 .617.503 1.12 1.12 1.12h4.67a1.12 1.12 0 0 0 1.12-1.12v-17.96a1.12 1.12 0 0 0-1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFD064"
      fillRule="evenodd"
      d="M64.4 72.443h-1.655a1.12 1.12 0 0 1 1.12 1.12v17.96c0 .617-.503 1.12-1.12 1.12H64.4a1.12 1.12 0 0 0 1.12-1.12v-17.96a1.12 1.12 0 0 0-1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FE646F"
      fillRule="evenodd"
      d="M46.902 79.389h4.67a1.12 1.12 0 0 1 1.12 1.12v11.015a1.12 1.12 0 0 1-1.12 1.12h-4.67a1.12 1.12 0 0 1-1.12-1.12V80.508a1.12 1.12 0 0 1 1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FD4755"
      fillRule="evenodd"
      d="M49.916 79.389h1.655a1.12 1.12 0 0 1 1.12 1.12v11.015a1.12 1.12 0 0 1-1.12 1.12h-1.655a1.12 1.12 0 0 0 1.12-1.12V80.508a1.12 1.12 0 0 0-1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#8AC9FE"
      fillRule="evenodd"
      d="M85.388 58.668h4.67a1.12 1.12 0 0 1 1.12 1.12v31.735a1.12 1.12 0 0 1-1.12 1.12h-4.67a1.12 1.12 0 0 1-1.12-1.12V59.788a1.12 1.12 0 0 1 1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#60B7FF"
      fillRule="evenodd"
      d="M88.403 58.668h1.655a1.12 1.12 0 0 1 1.12 1.12v31.735a1.12 1.12 0 0 1-1.12 1.12h-1.655a1.12 1.12 0 0 0 1.12-1.12V59.788a1.12 1.12 0 0 0-1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#6CF5C2"
      fillRule="evenodd"
      d="M77.23 65.191h-4.67a1.12 1.12 0 0 0-1.12 1.12v25.212a1.12 1.12 0 0 0 1.12 1.12h4.67a1.12 1.12 0 0 0 1.12-1.12V66.31a1.12 1.12 0 0 0-1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#00E499"
      fillRule="evenodd"
      d="M77.23 65.191h-1.656a1.12 1.12 0 0 1 1.12 1.12v25.212a1.12 1.12 0 0 1-1.12 1.12h1.655a1.12 1.12 0 0 0 1.12-1.12V66.31a1.12 1.12 0 0 0-1.12-1.12"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFECE3"
      fillRule="evenodd"
      d="M22.605 7.357c4.755 0 8.61 2.71 8.61 8.959S27.36 27.63 22.604 27.63s-8.609-5.065-8.609-11.314 3.855-8.959 8.61-8.959"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFDDCE"
      fillRule="evenodd"
      d="M22.606 7.357c4.754 0 8.609 2.71 8.609 8.959S27.36 27.63 22.605 27.63q-.495 0-.976-.073c4.294-.637 7.632-5.426 7.632-11.241s-3.337-8.564-7.632-8.919q.487-.04.976-.04"
      clipRule="evenodd"
    ></path>
    <path
      fill="#60B7FF"
      fillRule="evenodd"
      d="M13.592 92.643H31.56a1.957 1.957 0 0 0 1.955-1.95l.09-51.326c4.808.003 12.763-4.719 17.856-9.465 2.452-2.283 9.706-10.315 5.41-12.819-2.13-1.24-3.65-.776-4.825.414-4.856 4.92-10.23 12.458-18.329 12.458H13.16c-4.417 0-9.91 2.834-9.936 7.835l-.099 19.775c-.022 4.308 3.69 7.833 8.362 7.833l.151 25.304a1.956 1.956 0 0 0 1.954 1.941"
      clipRule="evenodd"
    ></path>
    <path
      fill="#41A8FC"
      fillRule="evenodd"
      d="M20.708 92.643h3.125V67.937a1.562 1.562 0 1 0-3.125 0zM10.087 65.29q.683.106 1.4.108h1.725V38.643a1.563 1.563 0 1 0-3.125 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default AnalyticsIcon;
