import * as React from "react";
import { SVGProps } from "react";
const PrintSignIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeWidth={1.2}
      d="M14.643 15H17a.5.5 0 0 0 .5-.5V9.166a3.333 3.333 0 0 0-3.333-3.333H5.833A3.333 3.333 0 0 0 2.5 9.166V14.5a.5.5 0 0 0 .5.5h2.357M6.667 5.833V3a.5.5 0 0 1 .5-.5h5.666a.5.5 0 0 1 .5.5v2.833"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.2}
      d="M5.081 16.93 5.357 15l.415-2.904a.5.5 0 0 1 .495-.429h7.466a.5.5 0 0 1 .495.43L14.643 15l.275 1.93a.5.5 0 0 1-.495.57H5.576a.5.5 0 0 1-.495-.57Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="m14.166 8.341.009-.009"
    />
  </svg>
);
export default PrintSignIcon;
