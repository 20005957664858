const ImmigrationTravelIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m13.437 10.023.9 5.41c.104.619-.098 1.25-.543 1.694l-.993.994-1.815-5.785M7.562 8.946l-5.739-1.8.994-.994a1.944 1.944 0 0 1 1.695-.544l5.409.901"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="m15.595 5.132-.044-.163a.847.847 0 0 0-.588-.595l-.161-.044a1.428 1.428 0 0 0-1.456.299L5.33 12.63l-1.76-.234-1.46 1.476 2.768 1.194 1.144 2.835 1.492-1.508-.208-1.785 8-7.999c.36-.403.47-.97.288-1.478Z"
    />
  </svg>
);
export default ImmigrationTravelIcon;
