import * as React from "react";
import { SVGProps } from "react";
const CongratulationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={120} height={121} fill="none" {...props}>
    <path
      fill="#ED3F03"
      d="m5.133 120.247 65.883-27.094c1.804-.75 2.437-2.953 1.336-4.781-3.07-5.18-9.516-15.235-17.696-23.415-7.922-7.921-17.531-13.968-22.547-16.874-1.828-1.055-4.007-.422-4.734 1.359L.258 115.395c-1.172 2.859 1.992 6.047 4.875 4.852Z"
    />
    <path
      fill="#FCB424"
      d="m32.531 108.992-15.398 6.328-11.93-11.953 6.328-15.375 21 21Zm27.867-11.484-15.375 6.328L16.664 75.5l6.328-15.398 37.406 37.406Z"
    />
    <path
      fill="#07B7EE"
      d="M57.21 62.797a.82.82 0 0 1-.046-1.078l25.594-30.985 6.515 6.516L58.29 62.844c-.305.258-.773.234-1.078-.047Z"
    />
    <path
      fill="#ED3F03"
      d="M62.133 67.043c3.797-3.304 21.258-17.625 38.086-15.375l.164 10.313s-13.664-6.375-37.172 6.61a.926.926 0 0 1-1.125-.165c-.375-.352-.375-.96-.023-1.336.023-.023.046-.047.07-.047Z"
    />
    <path
      fill="#FCB424"
      d="M52.969 57.898c3.304-3.796 17.625-21.257 15.375-38.086l-10.313-.164s6.399 13.665-6.61 37.172c-.21.375-.14.82.165 1.125a.934.934 0 0 0 1.336 0l.047-.047Z"
    />
    <path
      fill="#07B7EE"
      d="M99.586 20.588c-2.39 3.867-2.367 7.687 0 11.555.023.047.023.117-.023.14a.071.071 0 0 1-.094 0c-3.89-2.437-7.711-2.39-11.602 0-.047.024-.117.024-.14-.023a.071.071 0 0 1 0-.094c2.437-3.89 2.414-7.687.023-11.601-.023-.047-.023-.118.023-.141.024-.024.07-.024.118 0 3.867 2.414 7.664 2.414 11.554.023.047-.047.094-.047.14 0 .048.024.048.094 0 .14Z"
    />
    <path
      fill="#ED3F03"
      d="M119.906 59.681c-4.523.445-7.523 2.79-9.164 7.055-.023.047-.07.07-.14.047-.047-.024-.071-.047-.071-.094-.445-4.57-2.789-7.57-7.054-9.188-.047-.023-.071-.07-.047-.14.023-.047.047-.07.093-.07 4.571-.422 7.571-2.766 9.211-7.032.024-.047.071-.07.141-.047.047.024.07.047.07.094.446 4.547 2.766 7.547 7.008 9.188.047.023.07.093.047.14-.047.024-.07.047-.094.047Z"
    />
    <path
      fill="#FCB424"
      d="M60.82.595c-.468 4.524-2.789 7.547-7.054 9.164-.047.024-.07.07-.047.14.023.048.047.071.093.071 4.57.422 7.57 2.79 9.188 7.055.023.047.07.07.14.047.047-.024.071-.047.071-.094.422-4.57 2.766-7.57 7.031-9.211.047-.023.07-.07.047-.14-.023-.047-.047-.07-.094-.07C65.65 7.11 62.65 4.79 61.008.547c-.024-.047-.07-.07-.117-.047-.047.024-.07.047-.07.094Zm33.094 79.336-.61 2.297a.67.67 0 0 0 .188.68l1.688 1.664a.7.7 0 0 1 .023.984.67.67 0 0 1-.328.188l-2.297.609c-.258.07-.445.258-.492.492l-.61 2.297a.699.699 0 0 1-.843.516.513.513 0 0 1-.328-.188l-1.664-1.664c-.188-.187-.446-.258-.68-.187l-2.297.609a.69.69 0 0 1-.867-.492.58.58 0 0 1 0-.399l.61-2.297a.67.67 0 0 0-.188-.68l-1.664-1.663a.7.7 0 0 1-.024-.985.669.669 0 0 1 .328-.187l2.297-.61c.235-.07.446-.257.492-.492l.61-2.297a.699.699 0 0 1 .844-.515.667.667 0 0 1 .328.187l1.687 1.664c.188.188.445.258.68.188l2.297-.61a.695.695 0 0 1 .867.47c-.023.163-.023.304-.047.421Z"
    />
    <path
      fill="#ED3F03"
      d="m43.64 29.661-.609 2.297a.67.67 0 0 0 .188.68l1.664 1.687a.7.7 0 0 1 .023.985.668.668 0 0 1-.328.187l-2.297.61c-.234.07-.445.257-.492.492l-.61 2.297a.699.699 0 0 1-.843.515.669.669 0 0 1-.328-.187l-1.664-1.664c-.188-.188-.446-.258-.68-.188l-2.297.61a.695.695 0 0 1-.867-.47.582.582 0 0 1 0-.398l.61-2.296a.67.67 0 0 0-.188-.68l-1.664-1.664c-.281-.258-.281-.703-.024-.985a.67.67 0 0 1 .328-.187l2.297-.61c.235-.07.446-.257.493-.515l.609-2.297a.699.699 0 0 1 .844-.516.669.669 0 0 1 .328.188l1.664 1.687a.684.684 0 0 0 .68.188l2.297-.61a.691.691 0 0 1 .867.493.887.887 0 0 1 0 .351Z"
    />
  </svg>
);
export default CongratulationIcon;
