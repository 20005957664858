import React, { useEffect } from "react";
import { Box, Card } from "@mui/material";
import { checkDependantQuestionTitle } from "../../helper/service";
import { displayIcon } from "../../helper/render-service";

const FirstScreen = ({ questionGroup, formData, questionNodes }: any) => {
  const [windowWidth, setWindowWidth] = React.useState(0);

  useEffect(() => {
    let width = window.innerWidth;
    setWindowWidth(width);
  }, []);

  return (
    <div
      id={questionGroup?.id}
      className="h-full mainInnerContent"
      style={{
        width: "100%",
        paddingTop: windowWidth > 767 ? "70px" : "270px",
        paddingBottom: windowWidth > 767 ? "20px" : "470px",
      }}
    >
      <Box className="successful-block section_desc_container">
        <Card
          variant="outlined"
          className="successful-widget section_desc_card"
          style={{ top: windowWidth < 380 ? "80%" : "50%" }}
        >
          <div
            className="icon-box"
            style={{
              marginBottom: "12px",
              ...(questionGroup?.data?.boxIcon === "Kite" && { width: "50%" }),
            }}
          >
            {" "}
            {displayIcon(questionGroup?.data?.boxIcon)}
          </div>
          <span
            id={`${questionGroup?.id}_title`}
            className="section_desc_title"
            style={{ fontWeight: 700, fontSize: "22px", marginTop: "12px" }}
          >
            {checkDependantQuestionTitle(questionGroup, formData)}
          </span>
          <p className="section_description">
            {checkDependantQuestionTitle(questionNodes[0], formData)}
          </p>
        </Card>
      </Box>
    </div>
  );
};

export default FirstScreen;
