import PersonalInfoIcon from "../assets/icons/PersonalInfoIcon";
import ImmigrationTravelIcon from "../assets/icons/ImmigrationTravelIcon";
import FinancialIcon from "../assets/icons/FinancialIcon";
import DeclarationIcon from "../assets/icons/DeclarationIcon";
import EmploymentIcon from "../assets/icons/EmploymentIcon";
import EligibilityIcon from "../assets/icons/EligibilityIcon";
import MiscllaneousIcon from "../assets/icons/MiscllaneousIcon";
import FamilymarriageIcon from "../assets/icons/FamilymarriageIcon";
import CongratulationIcon from "../assets/icons/CongratulationIcon";
import EvidenceIcon from "../assets/icons/EvidenceIcon";
import SpecialFormsIcon from "../assets/icons/SpecialFormsIcon";
import AnalyticsIcon from "../assets/icons/AnalyticsIcon";
import BagIcon from "../assets/icons/BagIcon";
import ChecklistIcon from "../assets/icons/ChecklistIcon";
import CommandoIcon from "../assets/icons/CommandoIcon";
import CupIcon from "../assets/icons/CupIcon";
import DonateIcon from "../assets/icons/DonateIcon";
import FamilyIcon from "../assets/icons/FamilyIcon";
import FindIcon from "../assets/icons/FindIcon";
import HeadphoneIcon from "../assets/icons/HeadphoneIcon";
import KiteIcon from "../assets/icons/KiteIcon";
import MagnifierIcon from "../assets/icons/MagnifierIcon";
import MedicalIcon from "../assets/icons/MedicalIcon";
import MoneyIcon from "../assets/icons/MoneyIcon";
import NetworkIcon from "../assets/icons/NetworkIcon";
import NoPdfIcon from "../assets/icons/NoPdfIcon";
import NotepadIcon from "../assets/icons/NotepadIcon";
import PassportIcon from "../assets/icons/PassportIcon";
import ReportIcon from "../assets/icons/ReportIcon";
import SadIcon from "../assets/icons/SadIcon";
import SurveyIcon from "../assets/icons/SurveyIcon";
import TaskIcon from "../assets/icons/TaskIcon";
import TeamIcon from "../assets/icons/TeamIcon";
import ToureIcon from "../assets/icons/ToureIcon";
import UploadIcon from "../assets/icons/UploadIcon";
import DownloadIcon from "../assets/icons/DownloadIcon";
import PrintSignIcon from "../assets/icons/PrintSignIcon";
import NaviconsIcon from "../assets/icons/NaviconsIcon";

export function displayIcon(icon: any) {
  switch (icon) {
    case "personal":
      return <PersonalInfoIcon />;
    case "immigration":
      return <ImmigrationTravelIcon />;
    case "financials":
      return <FinancialIcon />;
    case "disclaimer":
      return <DeclarationIcon />;
    case "employment":
      return <EmploymentIcon />;
    case "eligibility":
      return <EligibilityIcon />;
    case "miscllaneous":
      return <MiscllaneousIcon />;
    case "familymarriage":
      return <FamilymarriageIcon />;
    case "congratulation":
      return <CongratulationIcon />;
    case "Evidence":
      return <EvidenceIcon />;
    case "medical":
      return <SpecialFormsIcon />;
    case "Analytics":
      return <AnalyticsIcon />;
    case "Bag":
      return <BagIcon />;
    case "Checklist":
      return <ChecklistIcon />;
    case "Commando":
      return <CommandoIcon />;
    case "Congratulations":
      return <CongratulationIcon />;
    case "Cup":
      return <CupIcon />;
    case "Donate":
      return <DonateIcon />;
    case "Family":
      return <FamilyIcon />;
    case "Find":
      return <FindIcon />;
    case "Headphone":
      return <HeadphoneIcon />;
    case "Kite":
      return <KiteIcon />;
    case "Magnifier":
      return <MagnifierIcon />;
    case "Medical":
      return <MedicalIcon />;
    case "Money":
      return <MoneyIcon />;
    case "Network":
      return <NetworkIcon />;
    case "NoPdf":
      return <NoPdfIcon />;
    case "Notepad":
      return <NotepadIcon />;
    case "Passport":
      return <PassportIcon />;
    case "Report":
      return <ReportIcon />;
    case "Sad":
      return <SadIcon />;
    case "Survey":
      return <SurveyIcon />;
    case "Task":
      return <TaskIcon />;
    case "Team":
      return <TeamIcon />;
    case "Toure":
      return <ToureIcon />;
    case "Upload":
      return <UploadIcon />;
    case "Pay":
      return <DownloadIcon />;
    case "Print":
      return <PrintSignIcon />;
    default:
      return <NaviconsIcon />;
  }
}
