import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { useAppDispatch, useAppSelector } from "../../store/store";
import Navicons from "../../assets/images/Navicons.svg";
import SideLogo from "../../assets/images/logo-white.png";
import Signouticon from "../../assets/images/Signouticon.svg";
import { getSummaryData } from "../../store/thunk/documentThunk";
import { addUserForm, getSetSectionId } from "../../store/thunk/formThunk";
import {
  anonymousUser,
  checkDependant,
  checkDependantGroup,
  checkDependantQuestion,
  checkDependantQuestionTitle,
  checkDependantSummary,
  structureArray,
} from "../../helper/service";
import ValidateFormJson from "../stepper/validateFormJson";

import { setAllNodeData, setAllSectionList } from "../../store/slices/flowFormSlice";
import { displayIcon } from "../../helper/render-service";

export default function SidebarComponent(props: any) {
  const { setIsSidebarVisible } = props;

  const role: any = localStorage.getItem("role") || localStorage.getItem("userType");
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const location = useLocation();
  const { logout } = useAuth0();

  const paymentLoading = useAppSelector((state: any) => state.notificationReducer.paymentLoading);

  let isAdmin = localStorage.getItem("userType")?.toLocaleLowerCase() == "admin" ? true : false;

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : null;

  const lawyerData: any = JSON.parse(localStorage.getItem("lawyerData") || "{}");
  const [isIframe, setIsIframe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sectionList, setSectionList] = useState<any[]>([]);
  const [nodeData, setNodeData] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [currentSection, setCurrentSection] = useState<any>();
  const [isUserDisable, setIsUserDisable] = useState(true);
  const [value, setValue] = useState<any>("Application");
  const [sidebarLogoURL, setSidebarLogoURL] = useState("");
  const [isInIframe, setIsInIframe] = useState(false);
  const [openSections, setOpenSections] = useState<any[]>([]);

  useEffect(() => {
    if (window.top !== window.self) {
      setIsInIframe(true);
    }
  }, [location]);

  const sectionId = useAppSelector((state: any) => state.formReducer.sectionid);
  const currentData = useAppSelector((state: any) => state.formReducer.currentData);
  const { sidebarLogo } = useAppSelector((state: any) => state.billingReducer);

  const currentQuestionList = useAppSelector((state: any) => state.formReducer.currentQuestionList);

  const { authConfig } = useAppSelector((state: any) => state.authReducer);

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);
  const isThirdParty = bootstrapData?.billingEntityType === "ThirdParty";
  const { getSignleFormData, uiFormPayload } = useAppSelector((state: any) => state?.formReducer);

  useEffect(() => {
    if (sidebarLogo !== null) {
      setSidebarLogoURL(sidebarLogo);
    }
  }, [sidebarLogo]);

  const toggleSection = (selectedId: any) => {
    if (openSections.includes(selectedId)) {
      // If the clicked section is already open, close it
      setOpenSections(openSections.filter((id) => id !== selectedId));
    } else {
      // If the clicked section is not open, close all other sections and open the clicked one
      setOpenSections([selectedId]);
    }
  };

  useEffect(() => {
    setCurrentSection(sectionId?.id);
  }, [sectionId?.id]);

  useEffect(() => {
    if (currentSection && !openSections.includes(currentSection)) {
      toggleSection(currentSection);
    }
  }, [currentSection]);

  useEffect(() => {
    if (!anonymousUser()) {
      setFormData({ ...uiFormPayload });
    }
  }, [uiFormPayload]);

  useEffect(() => {
    if (currentData && Object.keys(currentData || {}).length) setFormData({ ...currentData });
  }, [currentData]);

  useEffect(() => {
    if (bootstrapData)
      if (bootstrapData && !bootstrapData?.isDisabled && !bootstrapData?.isDeleted) {
        setIsUserDisable(false);
      }
  }, [bootstrapData]);

  useEffect(() => {
    const detectIframe = () => {
      try {
        if (window.self !== window.top) {
          setIsIframe(true);
        } else {
          setIsIframe(false);
        }
      } catch (e) {
        setIsIframe(true);
      }
      setLoading(false);
    };

    detectIframe();
  }, []);

  const convertIntoUiJson = (jsonData: any) => {
    try {
      let orderCounts: any = [];

      return (
        !!jsonData &&
        jsonData.length > 0 &&
        jsonData.map((val: any) => {
          let childList = jsonData
            .filter((row: any) => val.id === row.parentNode)
            .map((row: any) => row.id);
          let order = orderCounts.filter((row: any) => val.parentNode === row);
          orderCounts.push(val.parentNode);

          return {
            id: val.id,
            parentNode: val?.parentNode,
            order: (!!order?.length ? order?.length : 0) + 1,
            children: childList,
          };
        })
      );
    } catch (error) {
      alert("json is not correct.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const flowData = JSON.parse(getSignleFormData?.flowJson || "{}");
      const isFlowJsonEmpty = Object.keys(flowData).length === 0;

      if (getSignleFormData?.flowJson && !isFlowJsonEmpty) {
        const getValue = JSON.parse(getSignleFormData.flowJson);
        if (!getValue?.nodes || getValue?.nodes?.length === 0) return;

        const getJsonData = await convertIntoUiJson(getValue?.nodes);
        const mergedObj = getValue?.nodes?.flatMap((flowJsonData: any) =>
          getJsonData
            ?.filter((getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id)
            .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
        );
        setNodeData(mergedObj);

        const tempArr = structureArray(mergedObj);

        const sectionData = tempArr.filter(
          (nodesData: any) =>
            nodesData?.data?.nodeType?.toLowerCase() === "section" ||
            (nodesData?.data?.nodeType?.toLowerCase() === "text" && !!nodesData.data.nodeTitle)
        );

        if (sectionData?.length > 0) {
          setSectionList(sectionData);
        }
      } else {
        setSectionList([]);
      }
    };

    fetchData();
  }, [getSignleFormData]);

  useEffect(() => {
    dispatch(setAllSectionList(sectionList));
  }, [sectionList]);

  useEffect(() => {
    dispatch(setAllNodeData(nodeData));
  }, [nodeData]);

  useEffect(() => {
    if (location.pathname && sectionList.length) {
      const summaryID = location?.state?.summaryId;
      const dynamicPathName = decodeURI(location.pathname);
      const splittedArray = dynamicPathName?.split("/");
      const mainId = splittedArray[2];
      // Check if mainId is defined
      if (mainId) {
        const selectedSection = sectionList?.find((section) => section?.data?.nodeTitle === mainId);

        const currNodeTitle = sectionId?.sectionNodeData?.filter(
          (val: any) => val?.id != sectionId?.id
        );
        // Check if selectedSection is defined

        if (selectedSection && currNodeTitle?.data?.nodeTitle != mainId) {
          const findIndex = sectionList
            ?.filter((sec: any) => checkDependant(sec, formData))
            .findIndex((ele) => ele?.data?.nodeTitle === mainId);
          onSectionClick(selectedSection, findIndex, summaryID || "");
        }
      }
    }

    if (location.pathname == "/home") {
      setOpenSections([]);
    }
    window.history.replaceState(null, "", location.pathname);
  }, [location.pathname, sectionList, location?.state]);

  const onSectionClick = (section: any, index: any, summaryId: any = "") => {
    const summary = section?.childItem?.filter((val: any) => checkDependantSummary(val, formData));

    if (summaryId) {
      const activeSummary = section?.childItem?.filter(
        (val: any) => val?.id == summaryId && checkDependantSummary(val, formData)
      );

      const activeSummaryIndex = section?.childItem
        ?.filter((val: any) => checkDependantSummary(val, formData))
        .findIndex((val: any) => val?.id == summaryId && checkDependantSummary(val, formData));

      onClickSummary(section?.id, index, activeSummary[0]?.id, activeSummaryIndex);
    } else {
      toggleSection(section?.id);
      onClickSummary(section?.id, index, summary[0]?.id, 0);
    }
  };

  const onClickSummary = (
    id: any,
    index: any,
    summaryId: any,
    summary_index: any,
    close: any = false,
    summary?: any
  ) => {
    const currentSecData = sectionList?.find((data: any) => data?.id === id);

    // Track data for App Insights
    // if (summary !== undefined) {
    //   setAppInsightsData(summary?.data?.nodeTitle, authConfig);
    // }

    const sumarry = currentSecData?.childItem?.filter(
      (data: any) => data?.id === summaryId && data?.data?.nodeTitle?.toLowerCase() === "summary"
    );

    if (sumarry.length) {
      saveUpdatedField(true);
    } else {
      saveUpdatedField();
    }

    dispatch(
      getSetSectionId({
        id: id || "",
        summaryId: summaryId || "",
        sectionNodeData: nodeData,
        sectionList: sectionList,
        index: index,
        summaryIndex: summary_index,
      })
    );
    if (close) {
      setIsSidebarVisible(false);
    }
  };

  const renderSections = (sidebarItem: any[]) => {
    const renderedSections: any = [];
    let stopRendering = false;

    // Render eligibility sections
    sidebarItem.forEach((section, sectionIndex) => {
      // Section rendering conditions (based on nodeType)
      if (section?.data?.nodeType?.toLowerCase() === "text") {
        stopRendering = true;
      }

      if (!stopRendering) {
        const sectionElement = (
          <li
            className="sidebar-list sideBarShow sidebar_menu_list"
            id={section.id}
            key={section.id}
          >
            <div
              className={`${
                section?.id === sectionId?.id &&
                decodeURI(location.pathname) === `/eligibility-check/${section?.data?.nodeTitle}`
                  ? "active"
                  : ""
              } cursor-pointer flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group main_section_title_container`}
              onClick={() => {
                // setAppInsightsData(section?.data?.nodeTitle, authConfig);

                // Always navigate, regardless of eligibility checks
                navigate(`/eligibility-check/${section?.data?.nodeTitle}`);

                // Conditionally toggle section, but not impacting redirection
                if (
                  decodeURI(location.pathname) === `/eligibility-check/${section?.data?.nodeTitle}`
                ) {
                  toggleSection(section?.id);
                }
              }}
            >
              <span className="flex items-center main_section_icon">
                <span
                  className={`${
                    !isThirdParty
                      ? section?.id === sectionId?.id &&
                        decodeURI(location.pathname) ===
                          `/eligibility-check/${section?.data?.nodeTitle}`
                        ? "opacity-100"
                        : "opacity-20"
                      : ""
                  }`}
                >
                  {displayIcon(section?.data?.icon)}
                </span>
                {/* <img
                  className={`${
                    section?.id === sectionId?.id &&
                    decodeURI(location.pathname) ===
                      `/eligibility-check/${section?.data?.nodeTitle}`
                      ? "opacity-100"
                      : "opacity-20"
                  }`}
                  src={}
                  alt={section?.data?.icon}
                /> */}
                <span
                  className={`main_section_title ml-3 text-[#6B7280] text-[14px] ${
                    section?.id === sectionId?.id &&
                    decodeURI(location.pathname) ===
                      `/eligibility-check/${section?.data?.nodeTitle}`
                      ? "text-white"
                      : ""
                  }`}
                >
                  {checkDependantQuestionTitle(section, formData) || section?.data?.nodeTitle}
                </span>
              </span>
            </div>

            {openSections.includes(section.id) && (
              <ul className="space-y-2 open_sub_section_ul_list">
                {section?.childItem
                  ?.filter((val: any) => checkDependantSummary(val, formData))
                  .map((summary: any, summary_index: any) => {
                    const arr = section?.childItem?.filter((val: any) =>
                      checkDependantSummary(val, formData)
                    );
                    if (summary_index !== 0 && arr[summary_index - 1]?.childItem?.length) {
                      const groupList = arr[summary_index - 1]?.childItem
                        .filter((val: any) => checkDependantGroup(val, formData))
                        .map((val: any) =>
                          val.data?.properties?.shouldContinue === false ? false : true
                        );
                      if (groupList.includes(false)) {
                        stopRendering = true;
                      }
                    }
                    if (!stopRendering) {
                      return (
                        <li
                          id={summary?.id}
                          key={summary.id}
                          className="sideBarShow open_sub_section_li_list"
                        >
                          <div
                            className={`${
                              summary?.id === sectionId?.summaryId ? "active" : ""
                            } open_sub_section_list_container cursor-pointer flex items-center justify-between px-2 ml-3 py-2 my-1 text-white rounded-lg hover:text-gray-100 group`}
                            onClick={() =>
                              onClickSummary(
                                section?.id,
                                sectionIndex,
                                summary?.id,
                                summary_index,
                                true,
                                summary
                              )
                            }
                          >
                            <span className="open_sub_section_inner_container flex flex-wrap overflow-hidden	items-center ">
                              <span
                                className={`open_sub_section_title text-[#6B7280] flex-wrap text-[14px]  ${
                                  summary?.id === sectionId?.summaryId ? "text-white" : ""
                                }`}
                              >
                                {checkDependantQuestionTitle(summary, formData) ||
                                  summary?.data?.nodeTitle}
                              </span>
                            </span>
                          </div>
                        </li>
                      );
                    }
                  })}
              </ul>
            )}
          </li>
        );
        renderedSections.push(sectionElement);
      }
    });
    return [...renderedSections];
  };

  const handleNavClick = () => {
    setOpenSections([]);
    saveUpdatedField();
    dispatch(
      getSetSectionId({
        id: "",
        summaryId: "",
        questionGroupId: "",
        sectionNodeData: [],
        sectionList: [],
        index: "",
        summaryIndex: "",
      })
    );
    setIsSidebarVisible(false);
  };

  const saveUpdatedField = (isSummary: boolean = false) => {
    let queList = [...currentQuestionList];
    const unSavedFormValues = JSON.parse(sessionStorage.getItem("unSavedFormValues") || "{}");
    const updatedFormValues = JSON.parse(sessionStorage.getItem("updatedFormValues") || "{}");

    let list = queList.filter((val: any) => checkDependantQuestion(val, unSavedFormValues));
    let err = ValidateFormJson({ formData: unSavedFormValues, list });

    const filteredObj2 = Object.keys(updatedFormValues).reduce((acc: any, key: any) => {
      if (!err.hasOwnProperty(key)) {
        acc[key] = updatedFormValues[key];
      }
      return acc;
    }, {});

    // Optimise API call if form has been updated then call these apis
    if (Object.keys(updatedFormValues).length !== 0) {
      dispatch(
        addUserForm({
          form_id: 1073,
          form_data: JSON.stringify(filteredObj2),
        })
      ).then((res: any) => {
        if (isSummary) {
          dispatch(getSummaryData());
        }
      });
    } else {
      if (isSummary) {
        dispatch(getSummaryData());
      }
    }
  };

  const handleLogoutClear = async () => {
    const isIframe = window.self !== window.top;
    let redirectUrl = "";

    if (isIframe) {
      if (isThirdParty) {
        window.parent.postMessage({ action: "exitFromIframe" }, "*");
        return; // Reloads the parent window
      }
      if (lawyerData?.user?.hasOwnProperty("userPersona")) {
        // if isLawyer
        redirectUrl = window.IFRAME_LAWYER_REDIRECT_URL;
      } else {
        redirectUrl = window.IFRAME_ADMIN_REDIRECT_URL;
      }
      if (window.top) {
        window.top.location.href = redirectUrl;
      }
    } else {
      redirectUrl = window.CURRENT_HOST;

      // Shutdown Intercom for non-iframe users
      var w = window as any;
      var ic = w.Intercom;
      ic("shutdown");
      window.location.href = redirectUrl;
    }

    // Clear App Insights context
    const browserHistory = createBrowserHistory({ basename: "" } as any);
    var reactPlugin = new ReactPlugin();
    const clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
      autoCapture: true,
    };

    const configObj = {
      connectionString: authConfig?.appInsightsConnectionString,
      extensions: [clickPluginInstance, reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
      enableAutoRouteTracking: true,
    };
    const appInsights = new ApplicationInsights({ config: configObj });
    // appInsights.clearAuthenticatedUserContext();

    localStorage.clear();
    sessionStorage.clear();

    // Trigger the logout process with the proper redirect URL
    await logout({ logoutParams: { returnTo: redirectUrl } });
  };

  const handleLogout = async (e: any) => {
    const tempUpdatedFormValues: any = sessionStorage.getItem("updatedFormValues");

    if (tempUpdatedFormValues && Object.keys(tempUpdatedFormValues)?.length > 0) {
      dispatch(addUserForm({ form_data: tempUpdatedFormValues })).then((res: any) => {
        if (res?.payload?.status === 200) {
          handleLogoutClear();
        }
      });
    } else {
      handleLogoutClear();
    }
  };

  const showSidebar = () => {
    if (!anonymousUser() && !isUserDisable) {
      return true;
    } else if (isIframe) {
      return true;
    } else {
      return false;
    }
  };

  const THIRD_PARTY_UI = {
    menu: {
      bg: "#fff",
      item: {
        FontWeight: 600,
        bg: "transparent",
        color: "rgb(110, 110, 110)",
        active: { color: "rgb(33, 109, 227)", bg: "rgb(226, 238, 255)" },
        hover: { color: "rgb(33, 109, 227)", bg: "transparent" },
      },
    },
  };
  useEffect(() => {
    const themeColors: any = {
      "--tp-menu-bg": THIRD_PARTY_UI.menu.bg,
      "--tp-menu-border": THIRD_PARTY_UI.menu.bg?.toString()?.toLowerCase()?.includes("#fff")
        ? "1px solid rgb(229 231 235)"
        : "none",
      "--tp-menu-item-bg": THIRD_PARTY_UI.menu.item.bg,
      "--tp-menu-item-font-weight": THIRD_PARTY_UI.menu.item.FontWeight,
      "--tp-menu-item-color": THIRD_PARTY_UI.menu.item.color,
      "--tp-menu-item-active-color": THIRD_PARTY_UI.menu.item.active.color,
      "--tp-menu-item-active-bg": THIRD_PARTY_UI.menu.item.active.bg,
      "--tp-menu-item-hover-color": THIRD_PARTY_UI.menu.item.hover.color,
      "--tp-menu-item-hover-bg": THIRD_PARTY_UI.menu.item.hover.bg,
    };

    // Loop through the object and set each CSS variable dynamically
    Object.keys(themeColors).forEach((key) => {
      document
        .getElementById("sidebar-multi-level-sidebar")
        ?.style.setProperty(key, themeColors[key]);
    });
  }, []);
  return (
    <aside
      id="sidebar-multi-level-sidebar"
      className={`fixed top-0 left-0 z-[1200] bg-[#111827] h-full transition-transform -translate-x-full sm:translate-x-0 sidebar_container ${
        isThirdParty ? "third-party-ui" : ""
      }`}
      aria-label="Sidebar"
    >
      {loading ? (
        <Box className="sidebar-logo">Loading...</Box>
      ) : (
        <Box className="sidebar-logo">
          {isInIframe && role == "lawyer" ? (
            sidebarLogoURL ? (
              <img src={sidebarLogoURL} alt="Law Firm Logo" className="sideLogo" />
            ) : (
              <></>
            )
          ) : (
            <img src={SideLogo} alt="Logo" className="sideLogo" />
          )}
        </Box>
      )}
      {paymentLoading ? <Box className="paymentLoader" /> : ""}
      {/* All Menus */}
      <>
        {showSidebar() && (
          <div
            className={`${
              isAdmin ? "h-[calc(100%_-_179px)]" : "h-[calc(100%_-_165px)]"
            } px-[8px] overflow-x-hidden overflow-y-auto custom-scroll sidebar_menu_container`}
          >
            <ul className="space-y-2 font-medium p-0 menu_ul_container">
              <li className="sidebar-list sideBarShow menu_li_container">
                <div
                  className={`${
                    decodeURI(location.pathname) === `/home` ? "active" : ""
                  } rounded-lg `}
                >
                  <NavLink
                    to={"/home"}
                    key={"Home"}
                    onClick={handleNavClick}
                    className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group menu_navlink_home_container`}
                  >
                    <span className="flex items-center">
                      <span
                        className={`${
                          !isThirdParty
                            ? location.pathname === "/home"
                              ? "opacity-100"
                              : "opacity-20"
                            : ""
                        }`}
                      >
                        {displayIcon("")}
                      </span>

                      <span className="ml-3 text-[#6B7280] text-[14px]">Home</span>
                    </span>
                  </NavLink>
                </div>
              </li>

              {(isIframe || (!isIframe && localStorage.getItem("buttonClicked") !== "Admin")) &&
                renderSections(sectionList.filter((x: any) => checkDependant(x, formData)))}
            </ul>
          </div>
        )}
        {/* Bottom Menus */}
        <ul
          className={`singout_ul_container fixed min-h-[85px] flex flex-col justify-center  px-[16px] w-full bottom-0 space-y-2 font-medium p-0 border-t border-solid`}
          style={{ borderColor: isThirdParty ? "rgb(229 231 235)" : "#1f2937" }}
        >
          {paymentLoading ? <Box className="paymentLoader" /> : ""}

          {!anonymousUser() && (
            <li className="singout_list sidebar-list sideBarShow w-full">
              <span className="signout-email">
                {isIframe ? lawyerData?.user?.email : userData?.email}
              </span>
              {((isAdmin && !sessionStorage?.getItem("Usapath-Emulate-UserId")) ||
                !["lawyer", "admin"].includes(role)) && (
                <span
                  className=" flex items-center justify-between  px-2 py-2.5  text-white rounded-lg hover:text-gray-100 group"
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                >
                  <img src={Signouticon} alt="Signouticon" />
                  <span className="singout_txt flex-1 ml-3 ml-3 text-[#6B7280] text-[14px]">
                    {isThirdParty ? "Exit" : "SignOut"}
                  </span>
                </span>
              )}
            </li>
          )}
        </ul>
      </>
    </aside>
  );
}
