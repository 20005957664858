const PersonalInfoIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <mask
      id="a"
      width={14}
      height={7}
      x={1}
      y={11}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.667 11.633H14.32v5.916H1.667v-5.916Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.993 12.883c-2.316 0-5.077.297-5.077 1.718 0 1.127 1.709 1.698 5.077 1.698s5.077-.577 5.077-1.714c0-1.13-1.709-1.702-5.077-1.702Zm0 4.666c-1.73 0-6.327 0-6.327-2.948 0-2.968 4.762-2.968 6.327-2.968 2.716 0 6.327.306 6.327 2.952 0 2.964-4.762 2.964-6.327 2.964Z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.993 2.918c-1.67 0-3.03 1.36-3.03 3.03 0 1.67 1.36 3.028 3.03 3.028h.026a2.994 2.994 0 0 0 2.127-.891c.569-.571.88-1.33.876-2.135a3.034 3.034 0 0 0-3.029-3.032Zm0 7.308c-2.36 0-4.28-1.92-4.28-4.279s1.92-4.279 4.28-4.279c2.36 0 4.28 1.92 4.28 4.28a4.231 4.231 0 0 1-1.24 3.017 4.235 4.235 0 0 1-3.011 1.261h-.029ZM13.735 9.279a.626.626 0 0 1-.086-1.244 2.132 2.132 0 0 0 1.826-2.1 2.115 2.115 0 0 0-1.777-2.095.626.626 0 0 1 .201-1.234 3.36 3.36 0 0 1 2.826 3.33 3.39 3.39 0 0 1-2.902 3.337.616.616 0 0 1-.088.006Z"
      clipRule="evenodd"
    />
    <mask
      id="b"
      width={5}
      height={5}
      x={14}
      y={11}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.871 11.238h3.445v4.148H14.87v-4.148Z"
        clipRule="evenodd"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.58 15.386a.625.625 0 0 1-.222-1.209c.708-.268.708-.596.708-.736 0-.472-.559-.796-1.661-.96a.627.627 0 0 1-.527-.71.635.635 0 0 1 .71-.527c2.256.337 2.728 1.381 2.728 2.197 0 .608-.262 1.43-1.515 1.904a.624.624 0 0 1-.221.041Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default PersonalInfoIcon;
