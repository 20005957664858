import * as React from "react";
import { SVGProps } from "react";
const MiscllaneousIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M20.34 11.91c-.848-.848-2.332-.848-3.18 0l-5.25 5.25a2.23 2.23 0 0 0-.66 1.59v1.5c0 .413.338.75.75.75h1.5c.6 0 1.162-.233 1.59-.66l5.25-5.25c.427-.428.66-.99.66-1.59 0-.6-.233-1.162-.66-1.59Zm-1.058 2.123-5.25 5.25a.742.742 0 0 1-.532.217h-.75v-.75c0-.195.082-.39.217-.532l5.25-5.25a.76.76 0 0 1 1.058 0c.143.142.218.33.218.532 0 .203-.075.39-.218.533h.008Zm-5.782-.908c0-.623-.502-1.125-1.125-1.125s-1.125.502-1.125 1.125c0 .24.075.502.217.742-.24.21-.555.383-.967.383-.33 0-.66-.098-.975-.255.607-.87.975-1.912.975-2.745A2.257 2.257 0 0 0 8.25 9 2.257 2.257 0 0 0 6 11.25c0 .915.51 1.988 1.282 2.85-.18.098-.36.15-.532.15A.752.752 0 0 0 6 15c0 .412.338.75.75.75.622 0 1.207-.248 1.725-.637.63.397 1.32.637 2.025.637 1.95 0 3-1.86 3-2.625Zm-5.152-.075c-.51-.593-.848-1.29-.848-1.8 0-.412.338-.75.75-.75s.75.338.75.75c0 .54-.262 1.223-.652 1.8Zm1.402 5.7c0 .413-.338.75-.75.75H6.75C4.68 19.5 3 17.82 3 15.75v-9C3 4.68 4.68 3 6.75 3h4.32c.803 0 1.553.315 2.123.877l2.43 2.43c.57.57.877 1.32.877 2.123v1.32c0 .412-.337.75-.75.75a.752.752 0 0 1-.75-.75V8.43c0-.06 0-.12-.015-.18H12.75c-.825 0-1.5-.675-1.5-1.5V4.515c-.06 0-.12-.015-.18-.015H6.75A2.257 2.257 0 0 0 4.5 6.75v9A2.257 2.257 0 0 0 6.75 18H9c.412 0 .75.337.75.75Z"
    />
  </svg>
);
export default MiscllaneousIcon;
