import * as React from "react";
import { SVGProps } from "react";
const SurveyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FF6C52"
        d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Z"
      />
      <path
        fill="#F92814"
        d="M100 50c0-4.336-.552-8.543-1.59-12.554L76.005 15.039 23.996 84.961l13.45 13.45A50.084 50.084 0 0 0 50 100c27.614 0 50-22.386 50-50Z"
      />
      <path fill="currentColor" d="M23.996 15.04h52.008v69.92H23.996V15.04Z" />
      <path
        fill="#E9EDF5"
        d="M50 15.04h26.004v69.92H50V15.04ZM32.23 61.475h13.49v13.49H32.23v-13.49Z"
      />
      <path fill="#CDD2E1" d="M54.28 61.477h13.49v13.49H54.28v-13.49Z" />
      <path
        fill="#00C8C8"
        d="m59.463 75.947-8.18-8.18 4.143-4.144 4.037 4.037 11.31-11.31 4.144 4.143-15.454 15.453Z"
      />
      <path fill="#BEC3D2" d="M32.23 24.695h35.54v5.86H32.23v-5.86Z" />
      <path fill="#AFB4C8" d="M50 24.695h17.77v5.86H50v-5.86Z" />
      <path fill="#BEC3D2" d="M32.23 35.883h35.54v5.86H32.23v-5.86Z" />
      <path fill="#AFB4C8" d="M50 35.883h17.77v5.86H50v-5.86Z" />
      <path fill="#BEC3D2" d="M32.23 47.07h35.54v5.86H32.23v-5.86Z" />
      <path fill="#AFB4C8" d="M50 47.07h17.77v5.86H50v-5.86Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SurveyIcon;
