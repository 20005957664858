import React, { useRef, useState, useEffect } from "react";
import { Tooltip, Typography, Box } from "@mui/material";

const TruncatedText = ({ text = "", maxWidth = 200, sx }: any) => {
  const textRef = useRef<any>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowed(textRef.current.scrollHeight > textRef.current.clientHeight);
      }
    };

    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow); // Check on resize
    return () => window.removeEventListener("resize", checkOverflow);
  }, [text]);

  return (
    <Tooltip title={isOverflowed ? text : ""} disableHoverListener={!isOverflowed}>
      <Box
        sx={{
          maxWidth,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3, // Limit to 3 lines
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
          ...sx,
        }}
      >
        <Typography ref={textRef} variant="inherit">
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default TruncatedText;
