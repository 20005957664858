import * as React from "react";
import { SVGProps } from "react";
const MedicalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={120} height={120} fill="none" {...props}>
    <path
      fill="#FFAC96"
      d="m29.456 56.757-8.493-24.469a5.86 5.86 0 0 0-6.675-4.35 5.64 5.64 0 0 0-4.275 6.956l8.737 26.982 10.706-5.12Zm61.088 0 8.494-24.469a5.859 5.859 0 0 1 6.675-4.35 5.647 5.647 0 0 1 3.628 2.562 5.646 5.646 0 0 1 .647 4.394l-8.738 26.982-10.706-5.12Z"
    />
    <path
      fill="#FFBCAB"
      d="m46.962 66.904-16.576-9.716a5.614 5.614 0 0 0-7.124 1.273l-6.05-18.673a5.86 5.86 0 0 0-6.674-4.35 5.639 5.639 0 0 0-4.275 6.956l8.156 30.432a7.402 7.402 0 0 0 2.269 3.675l12.675 11.268a7.499 7.499 0 0 1 2.512 5.606v6h22.5v-19.53a15 15 0 0 0-7.413-12.94Zm62.501-31.466a5.86 5.86 0 0 0-6.675 4.35l-6.056 18.673a5.614 5.614 0 0 0-7.126-1.273l-16.575 9.716a14.999 14.999 0 0 0-7.406 12.942v19.53h22.5v-6a7.5 7.5 0 0 1 2.513-5.607L103.313 76.5a7.405 7.405 0 0 0 2.268-3.674l8.157-30.432a5.647 5.647 0 0 0-.647-4.394 5.65 5.65 0 0 0-3.628-2.562Z"
    />
    <path fill="#006DF0" d="M63.75 99.375v15H90v-15H63.75Zm-7.5 0H30v15h26.25v-15Z" />
    <path
      fill="#D80027"
      d="m85.2 34.8-1.33 1.331L60 60 36.133 36.13 34.8 34.8a16.876 16.876 0 1 1 23.868-23.85l1.332 1.331 1.33-1.331a16.874 16.874 0 1 1 23.87 23.85Z"
    />
    <path
      fill="#BD0022"
      d="M85.2 10.95a16.688 16.688 0 0 0-13.815-4.835A16.864 16.864 0 0 1 81.45 34.802l-1.331 1.331-21.994 21.992L60 60l23.869-23.868L85.2 34.8a16.875 16.875 0 0 0 0-23.85Z"
    />
    <path fill="#F1F2F2" d="M63.75 41.25v-7.5h7.5v-7.5h-7.5v-7.5h-7.5v7.5h-7.5v7.5h7.5v7.5h7.5Z" />
    <path
      fill="#FFAC96"
      d="m95.24 63.187-12.533 6.937a1.626 1.626 0 0 0 1 3.02c.22-.026.432-.096.624-.207l10.908-6a5.625 5.625 0 0 0 2.474-6.767 5.57 5.57 0 0 1-2.474 3.017Zm-70.478 0 12.532 6.937a1.626 1.626 0 0 1-1 3.02 1.622 1.622 0 0 1-.623-.207l-10.91-6a5.625 5.625 0 0 1-2.472-6.767 5.57 5.57 0 0 0 2.473 3.017Z"
    />
  </svg>
);
export default MedicalIcon;
