import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  LinearProgress,
  Button,
  CircularProgress,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useAppSelector } from "../../store/store";
import { addUserForm } from "../../store/thunk/formThunk";
import CustomProgressBar from "../progressBar";
import { anonymousUser } from "../../helper/service";
import SignInSignUpButton from "../../pages/payAndDownload/signInSignUpButton";
import { setPreviewDialog } from "../../store/slices/flowFormSlice";
import { getSummaryData } from "../../store/thunk/documentThunk";
import SectionGroupProgress from "../progressBarSummaryGroup";

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);
  const [isProgressbarVisible, setIsProgressbarVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [locked, setLocked] = useState<any>(false);
  const [isIframe, setIsIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionProgress, setSectionProgress] = useState("section");
  const sectionId = useAppSelector((state: any) => state.formReducer.sectionid);
  const { flowDataLoading } = useAppSelector((state: any) => state.notificationReducer);
  const { progressData } = useAppSelector((state: any) => state.documentReducer);
  const { getSignleFormData, uiFormPayload } = useAppSelector((state: any) => state?.formReducer);

  const progressBarRef = useRef<HTMLDivElement>(null);

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);
  const isThirdParty = bootstrapData?.billingEntityType === "ThirdParty";

  let localLawyerData: any = localStorage.getItem("lawyerData");
  const lawyerData: any = JSON.parse(localLawyerData);

  const localClickButton = localStorage.getItem("buttonClicked");
  const lockedApp = localStorage.getItem("lockedApp");

  const handleExit = () => {
    sessionStorage.removeItem("Usapath-Emulate-UserId");
    sessionStorage.removeItem("Usapath-Emulate-UserScope");
    sessionStorage.removeItem("Usapath-Session-Id");
    sessionStorage.removeItem("unSavedFormValues");
    if (isIframe) {
      localStorage.clear();
      sessionStorage.clear();
    }

    const isInIframe = window.self !== window.top;
    let redirectUrl;

    if (isInIframe) {
      if (lawyerData.user.hasOwnProperty("userPersona")) {
        // if isLawyer
        let baseUrl = window.IFRAME_LAWYER_REDIRECT_URL;

        baseUrl = baseUrl.replace("/iframe", "");

        redirectUrl = `${baseUrl}/client`;
        window.parent.postMessage({ type: "REDIRECT", url: redirectUrl }, baseUrl);
      } else {
        let baseUrl = window.IFRAME_ADMIN_REDIRECT_URL;
        baseUrl = baseUrl.replace("/iframe", "");

        redirectUrl = `${baseUrl}/form_emulation`;
        window.parent.postMessage({ type: "REDIRECT", url: redirectUrl }, baseUrl);
      }
    }
  };

  const exitFromEmulation = () => {
    const tempUpdatedFormValues: any = sessionStorage.getItem("updatedFormValues");
    if (tempUpdatedFormValues && Object.keys(tempUpdatedFormValues)?.length > 0) {
      dispatch(addUserForm({ form_data: tempUpdatedFormValues })).then(({ payload }: any) => {
        if (payload?.data) {
          handleExit();
        }
      });
    } else {
      handleExit();
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    let progressBarStyle = JSON.parse(getSignleFormData?.flowJson || "{}").settings
      ?.progressBarStyle;

    if (progressBarStyle) {
      dispatch(getSummaryData());
      setSectionProgress(progressBarStyle);
    }
  }, [getSignleFormData]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsIframe(window.self !== window.top);
  }, []);

  useEffect(() => {
    const isInIframe = window !== window.parent;

    if (isInIframe) {
      if (progressData) {
        const geSection = progressData?.find(
          (sectionData: any) => sectionData?.sectionKey === "General_Eligibility"
        );

        if (
          (bootstrapData && !bootstrapData?.isDisabled && !bootstrapData?.isDeleted && geSection) ||
          isInIframe
        ) {
          setIsProgressbarVisible(true);
        }
      }
    } else {
      if (progressData) {
        const geSection = progressData?.find(
          (sectionData: any) => sectionData?.sectionKey === "General_Eligibility"
        );

        if (
          bootstrapData &&
          !bootstrapData?.isDisabled &&
          !bootstrapData?.isDeleted &&
          geSection &&
          geSection?.percentComplete >= 88
        ) {
          setIsProgressbarVisible(true);
        }
      } else {
        setIsProgressbarVisible(false);
      }
    }
  }, [progressData, bootstrapData]);

  useEffect(() => {
    if (uiFormPayload?.ui_form_lock_state) {
      setLocked(uiFormPayload?.ui_form_lock_state);
      localStorage.setItem("lockedApp", uiFormPayload?.ui_form_lock_state);
    } else {
      setLocked(false);
      localStorage.setItem("lockedApp", "false");
    }
  }, [uiFormPayload]);

  useEffect(() => {
    if (location?.pathname?.includes("/home") && anonymousUser()) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);

  const handleUnLocking = async (flag: boolean) => {
    const tempUnSavedFormValues: any = sessionStorage.getItem("unSavedFormValues");
    const tempUpdatedFormValues: any = sessionStorage.getItem("updatedFormValues");

    let updatedFormValues = JSON.parse(tempUpdatedFormValues);
    let unSavedFormValues = JSON.parse(tempUnSavedFormValues);

    updatedFormValues = {
      ...updatedFormValues,
      ["ui_form_lock_state"]: flag,
    };

    await dispatch(
      addUserForm({
        form_data: JSON.stringify(updatedFormValues),
      })
    ).then((res: any) => {
      if (res?.payload?.data) {
        sessionStorage.setItem(
          "unSavedFormValues",
          JSON.stringify({ ...unSavedFormValues, ["ui_form_lock_state"]: flag })
        );
        localStorage.setItem("lockedApp", JSON.stringify(flag));
        setLocked(flag);
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const scrollToRight = () => {
    if (progressBarRef.current) {
      progressBarRef.current.scrollBy({
        left: 100,
        behavior: "smooth",
      });
    }
  };
  const scrollToLeft = () => {
    if (progressBarRef.current) {
      progressBarRef.current.scrollBy({
        left: -100,
        behavior: "smooth",
      });
    }
  };

  const navigate = useNavigate();
  const caseTypeDetails = JSON.parse(localStorage.getItem("caseTypeDetails") || "{}");
  const handleSwitchCaseType = () => {
    dispatch(setPreviewDialog(false));
    navigate("/home");
    localStorage.setItem("isCaseType", "false");
    setSectionProgress("section");
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold", color: "#555" }}>
          Making things ready for you, please wait...
        </Typography>
      </Box>
    );
  }

  const roleData: any = localStorage.getItem("role");

  return (
    <div>
      <div className="banner-height">
        {sessionStorage.getItem("Usapath-Emulate-UserId") &&
          roleData !== "client" &&
          !isThirdParty && (
            <Box
              className="emulate_user_banner_container"
              sx={{
                display: "flex",
                borderWidth: "2px",
                borderColor: "#da3b38",
                borderRadius: "8px",
                background: "#fccaca",
                fontWeight: "500",
                color: "#da3b38",
                py: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="emulating-user">
                Currently Emulating User -{localStorage.getItem("Usapath-Emulate-User")}
                {isIframe && (
                  <span className="space-between emulated_user_name">
                    {sessionStorage.getItem("Usapath-Emulate-UserName")}
                  </span>
                )}
              </div>
              <Button
                id="exit_btn"
                type="button"
                variant="text"
                sx={{
                  color: "#da3b38",
                  fontSize: "14px",
                  textTransform: "none",
                  fontWeight: "700",
                  p: 0,
                }}
                onClick={exitFromEmulation}
              >
                Exit
              </Button>
            </Box>
          )}

        {lockedApp === "true" && (
          <Box
            className="lock_state_banner_container"
            sx={{
              display: "flex",
              borderWidth: "2px",
              borderColor: "#da3b38",
              borderRadius: "8px",
              background: "#dc724180",
              fontWeight: "500",
              color: "#da3b38",
              py: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Application is currently in a locked state —
            <Button
              className="unlock_form_btn"
              type="button"
              variant="text"
              sx={{
                color: "#da3b38",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "700",
                p: 0,
              }}
              onClick={() => handleUnLocking(false)}
            >
              <LockOpenOutlinedIcon />
              Click here to unlock
            </Button>
          </Box>
        )}
      </div>
      {bootstrapData?.caseTypes?.length > 1 && (
        <Box
          className="current_case_type_banner_container"
          sx={{
            display: "flex",
            borderWidth: "2px",
            borderRadius: "8px",
            fontWeight: "500",
            py: 2,
            justifyContent: "center",
            alignItems: "center",
            background: "#2564eb1a",
            borderColor: "#2563eb",
            color: "#2563eb",
          }}
        >
          Current case type: <b className="ms-2"> {caseTypeDetails?.title}</b>
          <Button
            className="case_type_update_btn"
            type="button"
            variant="text"
            sx={{
              color: "#2563eb",
              fontSize: "14px",
              textTransform: "none",
              fontWeight: "700",
              marginLeft: "5px",
              textDecoration: "underline",
              p: 0,
            }}
            disabled={bootstrapData?.caseTypes?.length <= 1}
            onClick={handleSwitchCaseType}
          >
            Click here to Switch Case Type
          </Button>
        </Box>
      )}

      <header
        className="flex items-center justify-center bg-[#fff] md:px-[2rem] md:py-[25px] md:pb-[5px] 2xl:pb-[25px] border-b border-solid border-[#E5E7EB] header-height"
        style={!showHeader ? { display: "none" } : {}}
      >
        <div className="inner-header w" style={{ width: "100%" }}>
          <nav className="border-gray-200">
            <div
              className={`p-[5px] px-[26px] w-full overflow-auto custom-scrollbar ${
                isProgressbarVisible || isIframe ? "" : "hideScroll"
              }`}
            >
              {location.pathname === "/upload-document" && (
                <span className="upload_doc_btn Sen-Bold self-center text-sm md:text-lg font-extrabold max-w-[60%] whitespace-nowrap overflow-hidden text-ellipsis text-[#0C1932] m-[-0.875rem]">
                  Upload Documents
                </span>
              )}

              {anonymousUser() ? (
                <Box className="progressBarSecond">
                  <SignInSignUpButton source="header" />
                </Box>
              ) : (
                <>
                  {(localClickButton !== "Admin" || isIframe) && (
                    <>
                      <Box className="progressBarSecond">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            ref={progressBarRef}
                            style={{
                              overflowX: "auto",
                              width: "100%",
                            }}
                            className="custom-scrollbar"
                          >
                            {isProgressbarVisible || isIframe ? (
                              <>
                                {sectionProgress === "section" ? (
                                  <CustomProgressBar />
                                ) : (
                                  <SectionGroupProgress />
                                )}
                              </>
                            ) : (
                              // <CustomProgressBar />
                              <div className="anonymousText" style={{ padding: "20px 15px" }}>
                                <h1
                                  style={{
                                    fontWeight: "600",
                                    fontSize: windowWidth > 767 ? "14px" : "12px",
                                  }}
                                >
                                  Complete the eligibility quiz to see your detailed progress bar
                                </h1>
                              </div>
                            )}
                          </div>
                          {/* Scroll Button Right */}
                          <Button
                            id="right_scroll_btn"
                            sx={{
                              mr: 1,
                              whiteSpace: "nowrap",
                              position: "absolute",
                              right: 0,
                              minWidth: "34px",
                              px: "4px",
                              py: "10px",
                            }}
                            variant="contained"
                            onClick={scrollToRight}
                          >
                            →
                          </Button>
                          {/* Scroll Button Left */}
                          <Button
                            id="left_scroll_btn"
                            sx={{
                              transform: "rotate(180deg)",
                              ml: 1,
                              whiteSpace: "nowrap",
                              position: "absolute",
                              left: 300,
                              minWidth: "34px",
                              px: "4px",
                              py: "10px",
                            }}
                            variant="contained"
                            onClick={scrollToLeft}
                          >
                            →
                          </Button>
                        </div>
                      </Box>
                    </>
                  )}
                </>
              )}
            </div>
          </nav>
        </div>
      </header>
      {flowDataLoading && (
        <Box sx={{ width: "100%" }} className="progress_form_bar">
          <LinearProgress />
        </Box>
      )}
    </div>
  );
};

export default HeaderComponent;
