import * as React from "react";
import { SVGProps } from "react";
const ReportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <path
      fill="#E2E2E2"
      d="M55.849 67.425c-7.804-7.803-7.804-20.44 0-28.242a19.856 19.856 0 0 1 13.848-5.834V6.062a3.04 3.04 0 0 0-3.03-3.03H6.06a3.04 3.04 0 0 0-3.03 3.03V93.94a3.04 3.04 0 0 0 3.03 3.03h60.606a3.04 3.04 0 0 0 3.03-3.03V73.26c-5.015-.061-10.015-2-13.849-5.834Z"
    />
    <path
      fill="currentColor"
      d="M66.666 3.031H15.651v14.273c0 29.849 24.197 54.045 54.046 54.045V6.062a3.04 3.04 0 0 0-3.03-3.03Z"
      opacity={0.4}
    />
    <path
      fill="currentColor"
      d="M66.667 3.031H50.97a5.723 5.723 0 0 0 5.727 5.728h7.5v10.954a5.5 5.5 0 0 0 5.5 5.5V6.062a3.04 3.04 0 0 0-3.03-3.03Z"
      opacity={0.5}
    />
    <path fill="#8ABA55" d="M15.151 39.396h-6.06V18.184h6.06v21.212Z" />
    <path fill="#7CB5E8" d="M27.273 39.396h-6.061V12.123h6.06v27.273Z" />
    <path
      fill="currentColor"
      d="M23.863 33.987a2.742 2.742 0 0 0 2.743 2.742h.666V12.123h-3.409v21.864Zm-12.12 0a2.742 2.742 0 0 0 2.742 2.742h.666V18.184h-3.409v15.803Z"
      opacity={0.25}
    />
    <path
      fill="#FFAB03"
      d="M39.394 39.396h-6.06V24.244h6.06v15.152Zm0 36.363c0-8.363-6.788-15.151-15.152-15.151v15.151h15.152Z"
    />
    <path
      fill="#EF4A51"
      d="M24.242 60.607c-8.363 0-15.151 6.788-15.151 15.152 0 8.364 6.788 15.151 15.151 15.151 4.182 0 7.97-1.697 10.713-4.439L24.242 75.76V60.607Z"
    />
    <path fill="#4E6575" d="M24.242 75.758 34.954 86.47a15.104 15.104 0 0 0 4.44-10.712H24.242Z" />
    <path fill="#EF4A51" d="M57.576 84.85h6.06v6.06h-6.06v-6.06Z" />
    <path fill="#7CB5E8" d="M45.454 84.85h6.06v6.06h-6.06v-6.06Z" />
    <path fill="#8ABA55" d="M45.454 72.728h6.06v6.061h-6.06v-6.06Z" />
    <path
      fill="#4E6575"
      d="M69.97 73.273c11.029 0 19.97-8.94 19.97-19.97 0-11.028-8.941-19.969-19.97-19.969-11.03 0-19.97 8.94-19.97 19.97 0 11.029 8.94 19.97 19.97 19.97Z"
    />
    <path
      fill="#E2E2E2"
      d="M61.182 35.38c-3.651 7.454-2.41 16.711 3.803 22.908 6.197 6.197 15.455 7.455 22.91 3.803 3.65-7.454 2.408-16.712-3.804-22.909-6.212-6.197-15.454-7.454-22.909-3.803Z"
      opacity={0.2}
    />
    <path
      fill="#4E6575"
      d="M84.091 67.425a20.137 20.137 0 0 1-6.515 4.349l3.258 3.257a3.082 3.082 0 0 0 4.348 0l6.515-6.515a3.082 3.082 0 0 0 0-4.348L88.44 60.91a19.895 19.895 0 0 1-4.349 6.515Z"
    />
    <path
      fill="#E2E2E2"
      d="m91.697 64.168-3.258-3.258a19.895 19.895 0 0 1-5.788 7.818l1.652 1.652a1.573 1.573 0 0 0 2.212 0l5.636-5.636a3.99 3.99 0 0 0-.454-.576Z"
      opacity={0.2}
    />
    <path fill="#EF4A51" d="m90.606 69.592-4.334 4.348L96.97 84.652v-8.697l-6.363-6.363Z" />
    <path
      fill="#7CB5E8"
      d="M69.97 67.714c-3.849 0-7.47-1.5-10.182-4.212-5.621-5.622-5.621-14.758 0-20.364a14.277 14.277 0 0 1 10.182-4.212c3.849 0 7.47 1.5 10.182 4.212 5.621 5.621 5.621 14.758 0 20.364a14.313 14.313 0 0 1-10.182 4.212Z"
    />
    <path
      fill="currentColor"
      d="M69.97 38.91c-1.773 0-3.5.334-5.121.955-1.955 5.136-.879 11.182 3.257 15.303a14.315 14.315 0 0 0 10.182 4.212c1.773 0 3.5-.333 5.121-.955 1.955-5.136.88-11.181-3.257-15.303A14.313 14.313 0 0 0 69.97 38.91Z"
      opacity={0.25}
    />
    <path
      fill="#E2E2E2"
      d="M84.09 39.182c-4.242-4.242-9.909-6.166-15.454-5.803-2.879.197-2.651 4.697.242 4.546h.076c6.773-.47 13.258 3.53 15.712 10.5.576 1.636.652 3.379.743 5.12v.077c.212 2.848 4.515 2.515 4.515-.334 0-5.106-1.94-10.212-5.833-14.106Z"
      opacity={0.2}
    />
    <path
      fill="currentColor"
      d="M35.984 33.987a2.742 2.742 0 0 0 2.743 2.742h.667V24.244h-3.41v9.743Z"
      opacity={0.25}
    />
    <path
      fill="#E2E2E2"
      d="M59.363 87.077c0 1.136.91 2.045 2.046 2.045h2.227V84.85h-4.273v2.227Z"
      opacity={0.25}
    />
    <path
      fill="currentColor"
      d="M47.242 87.077c0 1.136.91 2.046 2.046 2.046h2.227V84.85h-4.273v2.227Zm0-12.121c0 1.136.91 2.045 2.046 2.045h2.227v-4.273h-4.273v2.228Z"
      opacity={0.25}
    />
    <path
      fill="#4E6575"
      d="M63.636 28.41H45.456a1.145 1.145 0 0 1-1.137-1.137c0-.621.516-1.136 1.137-1.136h18.181c.622 0 1.137.515 1.137 1.136 0 .621-.515 1.137-1.136 1.137Zm0-6.061H45.456a1.145 1.145 0 0 1-1.137-1.136c0-.622.516-1.137 1.137-1.137h18.181c.622 0 1.137.515 1.137 1.137 0 .62-.515 1.136-1.136 1.136Zm0-6.06h-9.09a1.145 1.145 0 0 1-1.137-1.137c0-.621.515-1.136 1.137-1.136h9.09c.622 0 1.137.515 1.137 1.136 0 .621-.515 1.136-1.136 1.136Z"
    />
    <path
      fill="currentColor"
      d="M69.97 46.864a2.664 2.664 0 0 1 2.652 2.651c0 .622.515 1.137 1.136 1.137.621 0 1.136-.515 1.136-1.137 0-2.318-1.62-4.257-3.788-4.772v-.91c0-.62-.515-1.136-1.136-1.136-.621 0-1.136.515-1.136 1.137v.909c-2.167.515-3.788 2.454-3.788 4.772a4.934 4.934 0 0 0 4.924 4.925 2.664 2.664 0 0 1 2.652 2.651 2.664 2.664 0 0 1-2.652 2.652 2.664 2.664 0 0 1-2.651-2.652c0-.621-.516-1.136-1.137-1.136s-1.136.515-1.136 1.136c0 2.318 1.621 4.258 3.788 4.773v.909c0 .621.515 1.136 1.136 1.136.621 0 1.136-.515 1.136-1.136v-.91c2.167-.514 3.788-2.454 3.788-4.772a4.934 4.934 0 0 0-4.924-4.924 2.664 2.664 0 0 1-2.651-2.652 2.664 2.664 0 0 1 2.651-2.651ZM62.122 56.062a1.515 1.515 0 1 0 0-3.03 1.515 1.515 0 0 0 0 3.03ZM78.789 56.062a1.515 1.515 0 1 0 0-3.03 1.515 1.515 0 0 0 0 3.03Z"
    />
  </svg>
);
export default ReportIcon;
