import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  circularProgressClasses,
  Grid,
} from "@mui/material";
import { useAppSelector } from "../../store/store";
import { useDispatch } from "react-redux";
import RightIcon from "../../assets/images/RightSVG.svg";
import {
  checkDependant,
  checkDependantQuestionTitle,
  checkDependantSummary,
  currentIdObjects,
  logErrorFunction,
} from "../../helper/service";
import BackDropLoader from "../loader/backDropLoader";
import { getSetSectionId } from "../../store/thunk/formThunk";
import TruncatedText from "../TruncatedText";

const SectionGroupProgress = (props: any) => {
  const dispatch = useDispatch();
  const { mainData, onClickLinkSummaryIDs, formMainData, setShowRefresh } = props;
  const [loadings, setLoading] = useState<any>(false);
  const [summaryData, setSummaryData] = React.useState<any>([]);
  const sectionId = useAppSelector((state: any) => state.formReducer.sectionid);
  const { getSignleFormData, uiFormPayload }: any = useAppSelector(
    (state: any) => state?.formReducer
  );
  const [activeSection, setActiveSection] = useState(0);
  const { summaryList } = useAppSelector((state: any) => state?.documentReducer);
  let [formData, setFormData] = useState<any>({});
  const [uiNodeList, setUiNodeList] = useState([]);

  //   useEffect(() => {
  //     setShowRefresh(true);
  //     return () => {
  //       setShowRefresh(false);
  //     };
  //   }, []);
  useEffect(() => {
    setFormData({ ...uiFormPayload });
  }, [uiFormPayload]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (summaryList?.length && !loadings && sectionId) {
      const nodeData = JSON.parse(getSignleFormData?.flowJson);
      const uiJson = JSON.parse(getSignleFormData.uiJson);
      const mainSectionData = nodeData?.nodes?.filter(
        (obj: any) =>
          obj?.parentNode === sectionId?.id && obj?.data?.nodeTitle?.toLowerCase() != "summary"
      );
      const tempData = mainSectionData
        ?.filter((obj: any) => checkDependantSummary(obj, formData))
        .map((obj: any) => {
          const sectiontemp = summaryList.filter(
            (object: any) => object?.sectionSummaryGroupKey == obj?.id
          );
          if (sectiontemp?.length) {
            return {
              ...obj,
              completePercentageData: {
                ...sectiontemp[0],
              },
            };
          } else {
            return {
              ...obj,
              completePercentageData: {
                missingFlowIds: [],
                percentComplete: 0,
                sectionKey: obj?.parentNode,
                sectionSummaryGroupKey: obj?.id,
              },
            };
          }
        });

      const tempUiJson = nodeData?.nodes?.flatMap((flowJsonData: any) =>
        uiJson
          ?.filter((getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id)
          .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
      );
      setUiNodeList(tempUiJson);
      setSummaryData(tempData);
      setActiveSection(sectionId?.index);
    }
  }, [summaryList, getSignleFormData, loadings, sectionId, formData]);

  const onClickUpdateInfoLink = (e: any, mainId: any, indexxx: any) => {
    e.preventDefault();
    const givenId = mainId;
    const nodesData = JSON.parse(getSignleFormData?.flowJson || "{}");
    const mainData = currentIdObjects(givenId, nodesData);
    const sectionLists: any = uiNodeList?.filter((val: any) => checkDependant(val, formData));
    const sectionListsFlowJson: any = nodesData?.nodes?.filter((val: any) =>
      checkDependant(val, formData)
    );
    const tempData = sectionLists
      .map((uiJsonObj: any) => {
        const flowJsonObj = sectionListsFlowJson.find(
          (flowJsonObj: any) => uiJsonObj?.id === flowJsonObj?.id
        );
        if (flowJsonObj) {
          return { ...flowJsonObj, ...uiJsonObj };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    dispatch(
      getSetSectionId({
        id: mainData?.currentSectionId || "",
        summaryId: mainData?.currentGroupSummaryId,
        questionGroupId: mainData?.currentQuestionGroupId,
        sectionNodeData: sectionId?.sectionNodeData,
        // Old Logic
        // sectionList: sectionLists,
        sectionList: tempData,
        index: activeSection,
        summaryIndex: indexxx,
      })
    );
  };

  const ShortTextWithTooltip = ({ text, maxLength = 20 }: any) => {
    // Function to shorten text
    const shortenText = (str: any, len: any) => {
      return str.length > len ? str.substring(0, len) + "..." : str;
    };
    return <span>{shortenText(text, maxLength)}</span>;
  };

  const showTitle = (data: any) => {
    const mainTiltle = checkDependantQuestionTitle(data, formData);

    return mainTiltle;
  };

  const onClickUpdate = (e: any, datas: any, index: any) => {
    if (datas?.missingFlowIds?.length > 0) {
      const idData = datas?.missingFlowIds?.[0];
      onClickUpdateInfoLink(e, idData, index);
    } else {
      logErrorFunction(
        `The ${datas?.sectionKey} section's ${datas?.sectionSummaryGroupKey} sectionsummarygroup have no missingFlowIds exist`
      );
    }
  };
  const renderSpaceWithUnderScore = (sectionId: string) => {
    let newStr = sectionId.replace(/[\s-]/g, "_");
    return newStr;
  };
  const getProgressCircle = (value = 0, fieldId: any) => {
    return (
      <Box id={`${fieldId}_${value}`} className="progress_circle_container" sx={{ height: "40px" }}>
        <CircularProgress
          id={`${fieldId}_100_completed`}
          variant="determinate"
          sx={{
            color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          id={`${fieldId}_incomplete`}
          variant="determinate"
          sx={{
            color: (theme) => (theme.palette.mode === "light" ? "#2563EB" : "#308fe8"),
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          value={value}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  };

  return (
    <>
      {!loadings && (
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"start"}
          gap={"40px"}
          className="progressBoxsContainer"
        >
          {summaryData &&
            summaryData?.length > 0 &&
            summaryData?.map((obj: any, index: any) => (
              <Box
                key={index}
                className="progressBox"
                onClick={(e: any) => onClickUpdate(e, obj?.completePercentageData, index)}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  id={obj?.id}
                  className="progress_box_container"
                  sx={{
                    position: "relative",
                    display: "table",
                    margin: "0 auto",
                  }}
                >
                  {obj.completePercentageData?.percentComplete &&
                  obj.completePercentageData?.percentComplete < 100 ? (
                    getProgressCircle(obj.completePercentageData?.percentComplete, obj.id)
                  ) : obj.completePercentageData?.percentComplete &&
                    obj.completePercentageData?.percentComplete === 100 ? (
                    <img
                      src={RightIcon}
                      width="40px"
                      height="40px"
                      style={{ zIndex: "5", position: "relative" }}
                    />
                  ) : (
                    getProgressCircle(obj?.completePercentageData?.percentComplete, obj?.id)
                  )}

                  <Box
                    id={`${obj.id}_percentage_container`}
                    className="progress_bar_percentage_container"
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      id={`${obj.id}_percentage`}
                      className="progress_bar_percentage_txt"
                      variant="caption"
                      component="div"
                      fontFamily="Sen"
                      color="#1C2333"
                    >{`${Math.round(
                      obj.completePercentageData?.percentComplete
                        ? obj.completePercentageData?.percentComplete
                        : 0
                    )}%`}</Typography>
                  </Box>
                </Box>
                <Typography
                  id={`${renderSpaceWithUnderScore(obj?.data?.nodeTitle)}`}
                  className="section-title progress_bar_section_name_txt"
                  sx={{
                    marginTop: "5px",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#1C2333",
                  }}
                  variant="caption"
                  component="div"
                  textAlign="center"
                  fontFamily="Sen"
                >
                  {/* <ShortTextWithTooltip text={showTitle(obj)} maxLength={15} />*/}
                  {<TruncatedText text={showTitle(obj)} sx={{ mb: 1 }} />}
                </Typography>
              </Box>
            ))}
        </Stack>
      )}
      {loadings && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress sx={{ justifyContent: "center", textAlign: "center" }} />
          </Box>
        </>
      )}
    </>
  );
};

export default SectionGroupProgress;
