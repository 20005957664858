import axios from "axios";
import Config from "../config/config";
import { anonymousUser } from "../helper/service";

const baseURL = Config.API_URL;

const apiClient = (isFormData = false, allowAuth = true) => {
  const headers: any = {
    "Access-Control-Allow-Origin": "*",
  };

  if (isFormData) {
    Object.assign(headers, { "content-type": "multipart/form-data" });
  }

  if (allowAuth) {
    headers["Authorization"] = `Bearer ${window.localStorage.getItem("accessToken")}`;
  }

  let instance = axios.create({
    baseURL,
    withCredentials: false,
    headers,
  });

  instance.interceptors.response.use(
    function (response) {
      if (response?.headers["token"]) {
        localStorage.setItem("accessToken", response?.headers["token"]);
      }
      return response;
    },
    function (error) {
      if ((error?.response?.status == 401 || error?.response?.status == 406) && !anonymousUser()) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      } else if (error?.response?.status === 500) {
        const errorScreen = document.getElementById("errorScreen");
        const errorTextSpan = errorScreen?.querySelector("span");

        if (errorTextSpan) {
          errorTextSpan.innerText = error?.config?.headers["UsaPath-Request-Id"];
        }

        errorScreen?.classList.add("shown");
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
export default apiClient;
