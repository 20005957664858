import * as React from "react";

const ChecklistIcon: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    fill="none"
    viewBox="0 0 120 120"
  >
    <path
      fill="#FF9F1C"
      d="M67.217 18.875H32.598a3.735 3.735 0 0 1-3.733-3.734v-1.767A3.734 3.734 0 0 1 32.6 9.637h34.62a3.736 3.736 0 0 1 3.737 3.735v1.767a3.736 3.736 0 0 1-3.738 3.738z"
    ></path>
    <path
      fill="#FF9F1C"
      d="M82.946 18.245v88.586a3.547 3.547 0 0 1-3.535 3.534H11.197A3.5 3.5 0 0 1 8.2 108.72c-.135-.218-.268-.432-.349-.676a3.6 3.6 0 0 1-.218-1.213V18.245a3.56 3.56 0 0 1 3.563-3.563H79.41c1.942 0 3.535 1.592 3.535 3.563"
    ></path>
    <path
      fill="#000"
      d="M20.858 110.366h-9.661a3.51 3.51 0 0 1-2.996-1.645c7.905-6.666 2.771-22.037 6.288-21.155.404.08.676.512.864.89 2.858 5.64 4.047 11.954 5.018 18.185.19 1.216.378 2.483.487 3.725"
    ></path>
    <path
      fill="#095DA4"
      d="M88.64 18.244v88.586c0 .081 0 .161-.028.216a3.544 3.544 0 0 1-3.535 3.319H16.891c-1.97 0-3.563-1.593-3.563-3.535V18.244a3.56 3.56 0 0 1 3.563-3.563h68.186a.5.5 0 0 1 .19.026 3.515 3.515 0 0 1 3.373 3.535z"
    ></path>
    <path
      fill="#19345D"
      d="M88.612 107.046a3.545 3.545 0 0 1-3.535 3.319H16.891c-1.97 0-3.563-1.593-3.563-3.535V18.244a3.56 3.56 0 0 1 3.563-3.563h68.186a.5.5 0 0 1 .19.026C66.333 21.58 40.653 16.67 25.66 32.25c-6.908 7.176-9.661 17.457-10.12 27.414-.7 14.38 3.563 29.898 14.841 38.828 7.988 6.288 18.485 8.555 28.658 9.013 9.85.406 19.698-.648 29.573-.458"
    ></path>
    <path
      fill="#A5C0DD"
      d="M85.35 88.316v14.989a2.877 2.877 0 0 1-2.88 2.88H19.503a2.877 2.877 0 0 1-2.88-2.88V25.872a2.877 2.877 0 0 1 2.88-2.88h62.64c2.095.306 3.12.982 2.967 3.447 0 .11-.022.219-.022.328-12.763-2.51-27.01-2.488-37.352 5.41-10.582 8.095-14.313 23.652-9.382 36.044 6.37 16.11 26.186 27.09 46.996 20.095"
    ></path>
    <path
      fill="#F2F8FD"
      d="M109.741 89.156c-23.313 12.17-43.065 12.953-67.324 5.856-3.103-.89-3.103-.323-4.67-2.51-15.109-21.128-19.075-39.745-20.83-65.57-.19-2.67.594-3.752 3.186-3.83 20.101-.677 41.365-2.403 61.36-.19 2.563.27 3.83.809 3.643 3.534 0 .109-.028.218-.028.325-1.571 26.044 7.245 39.255 26.094 57.15 1.942 1.835 1.538 3.668-1.431 5.235"
    ></path>
    <path
      fill="#D0DCEE"
      d="M109.743 89.167c-8.466 4.407-16.473 7.331-24.393 8.902-13.876 2.793-27.491 1.462-42.938-3.055-3.099-.894-3.099-.327-4.67-2.509-15.098-21.12-19.068-39.752-20.814-65.563-.314-4.27 1.975-3.766 6.175-3.95 19.2-.72 39.338-2.181 58.363-.087 2.717.247 3.8.982 3.644 3.535 0 .109-.022.218-.022.327-12.764-2.509-27.01-2.487-37.353 5.411-10.581 8.095-14.312 23.651-9.382 36.044 6.371 16.11 26.187 27.09 46.997 20.094 6.96-2.334 13.353-6.436 18.393-11.782a216 216 0 0 0 7.418 7.397c1.963 1.832 1.549 3.665-1.418 5.236"
    ></path>
    <path
      fill="#FF9F1C"
      d="M65.406 15.762H36.158a1.335 1.335 0 0 0-1.348 1.348v9.797c0 .754.593 1.348 1.348 1.348h29.25a1.336 1.336 0 0 0 1.35-1.348V17.11a1.334 1.334 0 0 0-1.35-1.348m-27.9 2.697h26.552v7.097H37.505z"
    ></path>
    <path
      fill="#F72F43"
      d="M72.342 14.25c0 .08 0 .19-.028.27a4.555 4.555 0 0 1-4.586 4.344H34.889a4.62 4.62 0 0 1-4.643-4.615c0-2.535 2.08-4.614 4.643-4.614h32.839a4.613 4.613 0 0 1 4.614 4.614"
    ></path>
    <path
      fill="#FFCA28"
      d="M11.413 98.708c-.297 3.401-1.323 6.829-3.563 9.338a3.6 3.6 0 0 1-.214-1.215V19.619c1.994 1.994 2.3 4.735 2.509 7.501 1.484 20.348-1.16 40.855.755 61.146.323 3.482.783 6.962.512 10.442"
    ></path>
    <path
      fill="#000"
      d="M105.163 78.04c-7.776-8.644-15.022-17.282-18.257-27.927-2.26-7.44-1.953-15.501-1.83-23.415a.273.273 0 0 1 .272-.268h.004c.15.002.27.126.269.277l-.03 2.116c-.31 19.658.95 27.703 19.976 48.851.242.27-.166.633-.404.367"
    ></path>
    <path
      fill="#00C6C5"
      d="M37.289 37.781v8.902a1.623 1.623 0 0 1-1.619 1.62h-8.204a1.623 1.623 0 0 1-1.616-1.618v-8.904c0-.916.728-1.619 1.618-1.619h8.204c.89 0 1.617.7 1.617 1.619"
    ></path>
    <path
      fill="#018E9A"
      d="M37.289 45.714v.971a1.624 1.624 0 0 1-1.619 1.619h-8.204a1.623 1.623 0 0 1-1.616-1.619v-8.904c0-.916.728-1.619 1.618-1.619H29.6a8.2 8.2 0 0 0-.54 4.938c.566 2.454 2.617 4.614 5.1 4.909 1.052.137 2.106-.053 3.129-.295"
    ></path>
    <path
      fill="#19345D"
      d="M78.822 42.69H46.47a.636.636 0 0 1-.637-.64v-.641c0-.352.284-.637.637-.637h32.352c.351 0 .637.283.637.637v.641c0 .354-.283.64-.637.64"
    ></path>
    <path
      fill="#F2F8FD"
      d="m30.955 45.694-2.775-2.26a.544.544 0 0 1 .276-.995.55.55 0 0 1 .411.152l2.291 1.861 3.694-6.13a.547.547 0 0 1 .936.562l-4.021 6.67a.545.545 0 0 1-.812.14"
    ></path>
    <path
      fill="#F72F43"
      d="M38.36 57.767c.297 2.625 1.03 5.538 1.883 8.064.343 1.008.037 1.619-1.07 1.999a17.1 17.1 0 0 1-8.057.763c-1.165-.176-1.727-.458-2.127-1.536-.997-2.696-1.686-6.13-1.953-8.96-.109-1.168.339-1.755 1.432-1.779q4.053-.08 8.105-.002c1.074.022 1.663.371 1.787 1.451"
    ></path>
    <path
      fill="#E2212E"
      d="M39.97 64.935c.086.5.226.338.33 1.002s.037 1.588-1.106 1.92c-2.636.77-5.352 1.195-8.075.741-1.184-.198-1.715-.451-2.13-1.54-1.033-2.719-1.625-6.098-1.972-8.972-.144-1.182.537-1.664 1.38-1.784.84-.118 1.352.074 2.111-.005-.432 1.752-.792 3.325-.111 5.11 1.019 2.675 3.676 4.554 6.537 4.501 1.213-.022 1.974-.589 3.037-.973M72.315 14.52a4.556 4.556 0 0 1-4.586 4.344H34.889a4.62 4.62 0 0 1-4.643-4.615c0-2.535 2.08-4.614 4.643-4.614h3.644c-1.595 1.809-1.658 3.622-.973 4.857.916 1.592 2.967 2.024 4.802 2.24 16.495 1.914 26.428-2.144 29.952-2.212"
    ></path>
    <path
      fill="#FFCA28"
      d="M64.651 25.368c.026.674-.486 1.266-1.08 1.536-.709.31.284.301-20.02.757-1.593.055-3.266.055-4.67-.702a4.514 4.514 0 0 1-1.374-6.799v5.396h26.55V24.18c.324.35.594.727.594 1.187"
    ></path>
    <path
      fill="#D0DCEE"
      d="M68.734 79.103c2.865 1.822 5.446.024 3.794-3.606a13.68 13.68 0 0 0-6.454-6.596c-.622-.301-1.374-.55-1.98-.225-2.863 1.528 3.154 9.48 4.64 10.427m5.339-10.885c1.551.989 2.95.015 2.055-1.953a7.4 7.4 0 0 0-3.498-3.573c-.335-.162-.741-.297-1.073-.12-1.549.829 1.71 5.133 2.516 5.646"
    ></path>
    <path
      fill="#E2212E"
      d="M52.828 15.559c.149-.153.168-.402.098-.605s-.218-.37-.37-.523c-.428-.432-3.404-2.413-3.37-1.058.027.97.682 1.673 1.535 1.985.502.183 1.658.663 2.107.2m3.622-2.566c.085-.087.096-.227.055-.343a.84.84 0 0 0-.208-.294c-.242-.242-1.922-1.362-1.902-.596.015.546.384.943.866 1.12.284.102.938.375 1.19.113"
    ></path>
  </svg>
);

export default ChecklistIcon;
