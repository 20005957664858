import * as React from "react";
import { SVGProps } from "react";
const NoPdfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FFBC4B"
        d="M6.384 0C2.874-.058 0 2.89 0 6.402v9.08a2.93 2.93 0 0 0 2.93 2.93h12.552a2.93 2.93 0 0 0 2.93-2.93v-3.453C18.411 5.386 13.026 0 6.383 0Z"
      />
      <path
        fill="#FFF095"
        d="M68.729 0H6.385a6.284 6.284 0 0 1 6.167 6.275v87.45A6.283 6.283 0 0 0 18.72 100c31.026 0 56.177-8.244 56.177-18.412V6.275A6.285 6.285 0 0 0 68.729 0Z"
      />
      <path
        fill="#FFDC64"
        d="M43.724 6.275V98.08C62.2 95.063 74.896 88.81 74.896 81.588V6.275A6.284 6.284 0 0 0 68.728 0H37.557a6.284 6.284 0 0 1 6.167 6.275Z"
      />
      <path
        fill="#FFBC4B"
        d="M84.519 81.588H28.035a2.93 2.93 0 0 0-2.93 2.93v9.206A6.276 6.276 0 0 1 18.828 100l62.236-.001c3.51.06 6.384-2.89 6.384-6.4v-9.082a2.93 2.93 0 0 0-2.93-2.93Z"
      />
      <path
        fill="#FF9C32"
        d="M84.519 81.588H56.276v12.136c0 3.46-2.8 6.265-6.257 6.275h31.045c3.51.06 6.384-2.888 6.384-6.4v-9.081a2.93 2.93 0 0 0-2.93-2.93Z"
      />
      <path
        fill="#004678"
        d="M90.794 71.757a2.93 2.93 0 0 1-2.93-2.93V56.275a2.93 2.93 0 1 1 5.86 0v12.552a2.93 2.93 0 0 1-2.93 2.93Z"
      />
      <path
        fill="#324150"
        d="M53.138 21.549H34.31a2.93 2.93 0 1 1 0-5.86h18.828a2.93 2.93 0 0 1 0 5.86Zm6.276 15.69h-31.38a2.93 2.93 0 0 1 0-5.859h31.38a2.93 2.93 0 1 1 0 5.86Zm0 12.552h-31.38a2.93 2.93 0 0 1 0-5.859h31.38a2.93 2.93 0 1 1 0 5.86Zm0 12.552h-31.38a2.93 2.93 0 0 1 0-5.859h31.38a2.93 2.93 0 0 1 0 5.86Z"
      />
      <path
        fill="#23303C"
        d="M59.414 74.897h-3.138a2.93 2.93 0 0 1 0-5.86h3.138a2.93 2.93 0 1 1 0 5.86Z"
      />
      <path
        fill="#324150"
        d="M40.794 74.897h-3.138a2.93 2.93 0 0 1 0-5.86h3.138a2.93 2.93 0 0 1 0 5.86Z"
      />
      <path
        fill="#F0F2F4"
        d="M90.794 59.206a2.928 2.928 0 0 1-2.07-.859l-6.277-6.276A2.927 2.927 0 0 1 81.59 50V9.206c0-.777.308-1.522.858-2.072L88.723.858a2.929 2.929 0 0 1 4.143 0l6.276 6.276A2.927 2.927 0 0 1 100 9.206V50c0 .777-.308 1.522-.858 2.071l-6.276 6.277a2.93 2.93 0 0 1-2.072.858Z"
      />
      <path
        fill="#23303C"
        d="M59.414 62.343a2.93 2.93 0 0 0 0-5.859h-15.69v5.86h15.69Zm0-12.552a2.93 2.93 0 1 0 0-5.859h-15.69v5.86h15.69Zm0-12.551a2.93 2.93 0 0 0 0-5.86h-15.69v5.86h15.69Zm-6.276-15.691a2.93 2.93 0 1 0 0-5.86h-9.414v5.86h9.414Z"
      />
      <path
        fill="#DEE1E5"
        d="M99.141 52.071A2.927 2.927 0 0 0 100 50V9.206c0-.777-.309-1.522-.859-2.072L92.865.858A2.93 2.93 0 0 0 90.794 0v59.206a2.93 2.93 0 0 0 2.071-.859l6.276-6.276Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default NoPdfIcon;
