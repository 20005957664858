import axios from "axios";
import { anonymousUser, getApiUrl, generateGuid } from "../helper/service";

const baseURL = window.API_URL;
let ongoingRequests: any = {};

const dotnetApiClient = (isFormData = false, allowAuth = true) => {
  // **** if requirement is to use different session ID for all the tabs  ****
  let sessionID = sessionStorage.getItem("Usapath-Session-Id");

  if (!sessionID) {
    sessionID = generateGuid();
    sessionStorage.setItem("Usapath-Session-Id", sessionID);
  }
  const headers: any = {
    "Access-Control-Allow-Origin": "*",
    "UsaPath-Request-Id": generateGuid(),
    "Usapath-Session-Id": sessionID,
  };

  if (isFormData) {
    Object.assign(headers, { "content-type": "multipart/form-data" });
  }

  if (allowAuth) {
    headers["Authorization"] = `Bearer ${window.localStorage.getItem("accessToken")}`;
    headers["csrfToken"] = `${window.localStorage.getItem("csrfToken")}`;
  }

  const usapathEmulateUserId = window.sessionStorage.getItem("Usapath-Emulate-UserId");

  if (usapathEmulateUserId) {
    headers["Usapath-Emulate-UserId"] = usapathEmulateUserId;
  }

  let instance = axios.create({
    baseURL,
    withCredentials: false,
    headers,
  });

  // Function to add request to ongoingRequests map
  const addRequestToQueue = (requestId: any) => {
    ongoingRequests[requestId] = true;
  };

  // Function to remove request from ongoingRequests map
  const removeRequestFromQueue = (requestId: any) => {
    delete ongoingRequests[requestId];
  };

  // Interceptor to handle request queue
  instance.interceptors.request.use(
    function (config: any) {
      // Check if a similar request is already in progress
      if (config?.url === "/uiFormPayload") {
        const requestId = config?.headers["usapath-tag"];

        if (ongoingRequests[requestId]) {
          // If yes, cancel the request
          return false;
        }

        // Add the request to the ongoingRequests map
        addRequestToQueue(requestId);
      }
      return config;
    },

    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response: any) {
      const requestId = response?.config?.headers["usapath-etag"];

      // Remove the completed request from the ongoingRequests map
      if (response?.config?.url === "/uiFormPayload") {
        removeRequestFromQueue(requestId);
      }
      if (response?.headers["token"]) {
        localStorage.setItem("accessToken", response?.headers["token"]);
      }
      return response;
    },

    function (error: any) {
      if (error?.config?.url === "/uiFormPayload" && error?.config?.headers["usapath-etag"]) {
        const requestId = error?.config?.headers["usapath-etag"]; // Remove the completed request from the ongoingRequests map
        removeRequestFromQueue(requestId);
      }
      if ((error?.response?.status == 401 || error?.response?.status == 406) && !anonymousUser()) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      } else if (error?.response?.status === 500) {
        const errorScreen = document.getElementById("errorScreen");
        const errorTextSpan = errorScreen?.querySelector("span");

        if (errorTextSpan) {
          errorTextSpan.innerText = error?.config?.headers["UsaPath-Request-Id"];
        }

        errorScreen?.classList.add("shown");
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
export default dotnetApiClient;
